/* -------------------------------------------- Mouduls and Component -----------------------------------*/
/* ================== General Mouduls ======================*/
import React, { Component } from 'react';
import axios from "axios";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link as Router} from 'react-router-dom'
import {withStyles} from "@material-ui/core/styles";

/* ================== Exclusive Mouduls ======================*/
import FormHelperText from "@material-ui/core/FormHelperText";
import { getUnits , getSubUnits} from "../../../../store/actions/categoryAction";
import {clearErrors, getProducts} from "../../../../store/actions/dataAction";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {Grid} from "@material-ui/core";
import Swal from 'sweetalert2'

let Toast = Swal.mixin({
    toast: true,
    position: 'bottom-start',
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})


/* ------------------------------------------------- Render Component ----------------------------------------------*/
class unitSelect extends Component {
    constructor(props){
        super(props);
        this.state = {
            errors : {},
            units : '',
            subUnits : ''
        }
    }

    componentDidMount(){
        this.props.getProducts()
        this.props.getUnits()
        this.props.getSubUnits()
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name] : event.target.value,
        });
    }

    updateUnits = (event , unit) =>{
        event.stopPropagation();
        axios.put('/product/update/'+this.props.match.params.id ,{
            units : unit ,
        }).then((res)=>{
            Toast.fire({
                icon: 'info',
                title: 'Please also enter the unit you are using'
            })
        }).catch((err) =>{
            Toast.fire({
                icon: 'error',
                title: 'There is a Problem . please check it'
            })
            console.log(err)
        })
    }


    updateSubUnits = (event , subUnit) =>{
        event.stopPropagation();
        axios.put('/product/update/'+this.props.match.params.id ,{
            subUnits : subUnit ,
        }).then((res)=>{
            Toast.fire({
                icon: 'success',
                title: 'The unit of this product was selected correctly'
            })
        }).catch((err) =>{
            Toast.fire({
                icon: 'error',
                title: 'There is a Problem . please check it'
            })
            console.log(err)
        })
    }


    nextUploadPage = () => {
        if(this.state.units !== '' && this.state.subUnits !== ''){
            window.location.href = `/createProduct/upload/${this.props.match.params.id}`
        }else{
            Toast.fire({
                icon: 'warning',
                title: 'Please select the product base unit correctly'
            })
        }
    }



    /* ========================== Dedicated Methods ===============================*/
    render(){
        const { classes ,ui: {loading} , units  , subUnits, products} = this.props;
        const {errors}   = this.state

        console.log(this.state.units)

        return(
            <Grid container className={classes.root}>
                <Grid item md={12}>
                    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                        <Link color="inherit" href='/'>
                            Home
                        </Link>
                        <Link color="inherit" href="/search">
                            Search Or Create
                        </Link>
                        <Typography color="textPrimary">
                            {products.map((product)=>{
                                return(
                                    <div key={product.id}>
                                        {product.id === this.props.match.params.id ? <Link component={Router} to={`/createProduct/subCategories/${this.props.match.params.id}/${product.categoryId }`} style={{color : '#403232' , fontFamily :'Roboto'}}>{product.categoryId}</Link> : ''}
                                    </div>
                                )
                            })}
                        </Typography>
                        <Typography color="textPrimary" className={classes.selectedBreadcrumb}>
                            {products.map((product)=>{
                                return(
                                    <div key={product.id}>
                                        {product.id === this.props.match.params.id ? product.subCategoryId : ''}
                                    </div>
                                )
                            })}
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item md={12}>
                    <Typography className={classes.carouselText}>Select the unit(s) : </Typography>
                </Grid>
                <Grid item md={12}>
                    <div className={classes.mainCategory}>
                        <FormControl variant="outlined" style={{margin:'2% 0 0 24.8%'}}>
                            <InputLabel id="baseUnit" className={classes.labelInput}>Base Unit</InputLabel>
                            <Select label="baseUnit" labelId="baseUnit" name="units"  value={this.state.units} className={classes.unitsInput} onChange={this.handleChange}>
                                <MenuItem value=""> <em>Please Select Base Unit</em> </MenuItem>
                                {units.map(unit =>{
                                    return (
                                        <MenuItem key={unit.id} value={unit.title} onClick={(event) => {this.updateUnits(event , unit.title)}}>{unit.title}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </Grid>
                <Grid item md={12}>
                    <div className={classes.mainCategory}>
                        <FormControl variant="outlined" style={{margin:'0 0 4% 24.8%'}}>
                            <InputLabel id="subUnits" className={classes.labelInput}>Units</InputLabel>
                            <Select label="subUnits" labelId="subUnits" name="subUnits"  value={this.state.subUnits} className={classes.unitsInput} onChange={this.handleChange}>
                                <MenuItem value=""> <em>Please Select Unit</em> </MenuItem>
                                {subUnits.map(subUnit =>{
                                    if(this.state.units === subUnit.unitId){
                                        return (
                                            <MenuItem key={subUnit.id} value={subUnit.title} onClick={(event) => {this.updateSubUnits(event , subUnit.title)}}>{subUnit.title}</MenuItem>
                                        )
                                    }
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </Grid>
                <Grid item md={12}>
                    <Grid container>
                        <Grid item md={6}>
                            {products.map((product)=>{
                                return(
                                    <div key={product.id}>
                                        {product.id === this.props.match.params.id ?
                                            <Link component={Router} to={`/createProduct/subCategories/${this.props.match.params.id}/${product.categoryId }`}><Typography className={classes.backText}>
                                                <KeyboardBackspaceIcon style={{margin :'-7px 8px -7px 0px'}} />
                                                Back
                                            </Typography></Link>
                                            : ''
                                        }
                                    </div>
                                )
                            })}
                        </Grid>
                        <Grid item md={6}>
                            <Link onClick={this.nextUploadPage}><Typography className={classes.forwardText}>
                                Next
                                <ArrowForwardIcon style={{margin :'-7px 0px -7px 12px'}} />
                            </Typography></Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}



/* ------------------------------------------------ Database Connection --------------------------------------------*/


unitSelect.propTypes ={
    classes       : PropTypes.object.isRequired,
    getUnits      : PropTypes.func.isRequired,
    getSubUnits    : PropTypes.func.isRequired,
    clearErrors   : PropTypes.func.isRequired,
    getProducts   : PropTypes.func.isRequired,

    units         : PropTypes.array.isRequired,
    subUnits      : PropTypes.array.isRequired,
    products      : PropTypes.array.isRequired,
    ui            : PropTypes.object.isRequired
}




const mapStateToProps = (state)=> ({
    units         : state.category.units,
    subUnits      : state.category.subUnits,
    products      : state.data.products,
    ui            : state.ui,
})



const mapActionsToProps = {
    getProducts,
    getUnits,
    getSubUnits,
    clearErrors
}



/* --------------------------------------------------- Styles Component ----------------------------------------------*/
const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },

    breadcrumb : {
        margin : '3.5% 0 0 7.7%',
        color : '#403232',
        fontWeight : '500',
        fontSize :'16px',
        fontFamily : 'Roboto'
    },

    selectedBreadcrumb : {
        color : '#0D85E5',
        fontFamily : 'Roboto'
    },

    mainTitle :{
        color : '#717171',
        fontWeight : '500',
        fontFamily :'Roboto'
    },


    carouselText :{
        textAlign :'center',
        margin : '4.2% 0 1.2% 0',
        fontWeight : '800',
        fontSize : 20,
        color : '#403232'
    },

    mainCategory :{
        marginLeft : '7.1%',
        marginTop :'1%',
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(14),
            height: theme.spacing(8),
        },
    },

    unitsInput :{
        width: 550,
        backgroundColor :'#FCFCFC'
    },

    backText:{
        textAlign : 'left',
        fontFamily :'Roboto',
        margin : '3.2% 0 0 15.2%',
        color : '#0F2741',
        cursor: 'pointer',
        textDecoration :'none'
    },

    forwardText:{
        textAlign : 'right',
        fontFamily :'Roboto',
        margin : '3.2% 15.2% 0 0',
        color : '#0F2741',
        cursor: 'pointer',
        textDecoration :'none'
    }
});



export default connect(mapStateToProps , mapActionsToProps)(withStyles(styles)(unitSelect));