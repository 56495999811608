/* -------------------------------------------- Mouduls and Component -----------------------------------*/
/* ================== General Mouduls ======================*/
import React, { Component } from 'react'
import { withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux'
import PropTypes from 'prop-types';
import axios from "axios";
import {storage} from "../../../config/config";
import {countries} from '../../../config/countries'
import {getCategories , getSubCategories , getUnits , getSubUnits } from '../../../store/actions/categoryAction'
import {singleProduct , getProducts , getSubProducts , getStores , getTags} from '../../../store/actions/dataAction'

/* ================== Exclusive Mouduls ======================*/
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Autocomplete  from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from "@material-ui/core/InputBase";
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import chart from '../../../images/single/chart.png'
import PersonPinCircleOutlinedIcon from '@material-ui/icons/PersonPinCircleOutlined';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import { Button } from '@material-ui/core';
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AddAPhotoOutlinedIcon from "@material-ui/icons/AddAPhotoOutlined";
import Accordion from "@material-ui/core/Accordion";
import Chip from '@material-ui/core/Chip';

import Swal from 'sweetalert2'
let Toast = Swal.mixin({
    toast: true,
    position: 'bottom-start',
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})




/* ------------------------------------------------- Render Component ----------------------------------------------*/
class details extends Component {

    constructor(props){
        super(props);

        this.state = {
            brand         : '',
            unitsEntry    : '',
            amount         : '',
            price         : '',
            currency      : '',
            store         : '',
            resultStores  : [],
            tag           : '',
            resultTags    : [],
            beforeImage   : '',
            imageEntry    : '',
            productId     :this.props.match.params.id,

            filter        : '',


            results       : [],
            errors        : {},
        }
    }


    componentDidMount(){
        this.props.getProducts()
        this.props.getSubProducts()
        this.props.getCategories()
        this.props.getSubCategories()
        this.props.getUnits()
        this.props.getSubUnits()
        this.props.getStores()
        this.props.getTags()
    }



    handleChange = (event) => {
        this.setState({
            [event.target.name] : event.target.value,
        });
    }

    handleInputChange = (event, value) => {
        this.setState({
            currency : value
        })
    }


    handleStore = (value) => {
        this.setState({
            resultStores : this.state.resultStores.concat(value.target.textContent)
        })
    }


    handleTag =(value)=>{
        this.setState({
            resultTags : this.state.resultTags.concat(value.target.textContent)
        })
    }


    handleConcatStore = (event)=>{
        this.setState({
            [event.target.name] : event.target.value.trim(),
        });

        if(event.key === 'Enter'){
            event.preventDefault();
            this.state.resultStores.push(this.state.store.trim())
        }
    }

    handleConcatTag = (event)=>{
        this.setState({
            [event.target.name] : event.target.value.trim(),
        });

        if(event.key === 'Enter'){
            event.preventDefault();
            this.state.resultTags.push(this.state.tag.trim())
        }
    }


    handleGetImage = (event) =>{
        if(event.target.files[0]){
            this.setState({
                beforeImage : event.target.files[0]
            })
        }
    }



    handleFilter = (event) => {
        event.preventDefault();

        this.setState({
            [event.target.name] : event.target.value,
        });  
        
        axios.get(`/filterSubProduct/${this.state.filter}`).then((res)=>{
            this.setState({
                results : res.data
            })
        }).catch((err) =>{
            console.log(err)
        })


        if(this.state.filter === ''){
            this.setState({
                results : []
            })
        }

    };



    handleUpload = () => {
        if(this.state.beforeImage !== ''){
            const uploadTask =storage.ref(`products/${this.state.beforeImage.name}`).put(this.state.beforeImage)
            uploadTask.on("state_changed" , snapshot => {} , error => {console.log(error)} , () => {
                storage.ref('products').child(this.state.beforeImage.name).getDownloadURL().then((url) => {
                    this.setState({
                        imageEntry : url,
                    })
                }).then(()=>{
                    Toast.fire({
                        icon: 'success',
                        title: 'Upload Product Image Successfully'
                    })
                })
            })
        }else{
            Toast.fire({
                icon: 'error',
                title: 'Please select your image first'
            })
        }
    }


    handleSubmit =(event) => {
        event.preventDefault();
        axios.post('/subProduct/create' , this.state).then((res)=>{
            const {store , resultStores , tag , resultTags}  = this.state

            if(resultStores.length < 1){
                const newStore = {
                    productId     : this.props.match.params.id,
                    subProductId  : res.data.id,
                    title         : store,
                    address       : '',
                    phone         : '',
                };
                axios.post('/store/create' , newStore).then(()=>{}).catch((error) =>{console.log(error)})
            }else{
                for (const item of resultStores) {
                    if(item  !== ''){
                        const newStores = {
                            productId     : this.props.match.params.id,
                            subProductId  : res.data.id,
                            title         : item,
                            address       : '',
                            phone         : '',
                        };
                        axios.post('/store/create' , newStores).then(()=>{}).catch((error) =>{console.log(error)})
                    }
                }
            }

            if(resultTags.length < 1){
                const newTag = {
                    productId    : this.props.match.params.id,
                    subProductId : res.data.id ,
                    title        : tag,
                };
                axios.post('/tag/create' , newTag).then(()=>{window.location.href = `/product/${this.props.match.params.id}`}).catch((error) =>{console.log(error)})
            }else{
                for (const item of resultTags) {
                    if(item  !== ''){
                        const newTags = {
                            productId     : this.props.match.params.id,
                            subProductId  : res.data.id,
                            title         : item,
                        };
                        axios.post('/tag/create' , newTags).then(()=>{window.location.href = `/product/${this.props.match.params.id}`}).catch((error) =>{console.log(error)})
                    }
                }
            }

        }).then(()=>{
            this.setState({
                brand         : '',
                unitsEntry    : '',
                price         : '',
                amount        : '',
                currency      : '',
                store         : '',
                tag           : '',
                beforeImage   : '',
                imageEntry    : '',
                productId     : '',
            })
        }).then(()=>{
            Toast.fire({
                icon: 'success',
                title: 'Add entry data Successfully'
            })
        }).catch((err) =>{
            this.setState({
                errors : err.response.data
            })
            Toast.fire({
                icon: 'error',
                title: 'There is a Problem . please check it'
            })
        })
    }



/* ========================== Render Component ===============================*/
    render() {
        const { classes , products ,subProducts,subUnits ,stores,tags, ui: {loading}  , categories , subCategories , units} = this.props;
        const {errors , imageEntry ,beforeImage , store , tag , results , filter}   = this.state

        console.log(filter)

        console.log(results)


        function countryToFlag(isoCode) {
            return typeof String.fromCodePoint !== 'undefined'
              ? isoCode
                  .toUpperCase()
                  .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
              : isoCode;
          }

/* -------------------------- Table Config ------------------------------------------*/

          const StyledTableCell = withStyles((theme) => ({
                head: {
                    backgroundColor: '#FCFCFC',
                    color: '#403232',
                },

                body: {
                    fontSize: 14,
                },
          }))(TableCell);

          
          const StyledTableRow = withStyles((theme) => ({
                root: {
                    '&:nth-of-type(odd)': {
                        // backgroundColor: theme.palette.action.hover,
                    },
                },
          }))(TableRow);
          



        return (
            <Grid container className={classes.root}>
                <Grid item md={12}>
                    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                        <Link color="inherit" href='/'>
                            Home
                        </Link>
                        <Grid color="textPrimary">
                            {products.map((product)=>{
                                return(
                                    <div key={product.id}>
                                        {product.id === this.props.match.params.id ? <Link  style={{color : '#403232' , fontFamily :'Roboto'}}>{product.categoryId}</Link> : ''}
                                    </div>
                                )
                            })}
                        </Grid>
                        <Grid color="textPrimary" className={classes.selectedBreadcrumb}>
                            {products.map((product)=>{
                                return(
                                    <div key={product.id}>
                                        {product.id === this.props.match.params.id ? <Link style={{color : '#403232' , fontFamily :'Roboto'}}>{product.subCategoryId}</Link> : ''}
                                    </div>
                                )
                            })}
                        </Grid>
                        <Grid color="textPrimary" className={classes.selectedBreadcrumb}>
                            {products.map((product)=>{
                                return(
                                    <div key={product.id}>
                                        {product.id === this.props.match.params.id ? product.title : ''}
                                    </div>
                                )
                            })}
                        </Grid>
                    </Breadcrumbs>
                </Grid>
                <Grid item md={12}>
                    <div className={classes.lastUpdate}>
                        <Grid container>
                            <Grid item md={1}>
                                <Grid color="inherit" style={{fontFamily:'Roboto'}}>Filter Data : </Grid>
                            </Grid>
                            <Grid item md={6}>
                                <Paper component="form" className={classes.search}>
                                    <IconButton className={classes.iconButton} aria-label="menu">
                                        <SearchIcon />
                                    </IconButton>
                                    <InputBase  name="filter" id="filter" value={this.state.filter}  onChange={this.handleFilter}  className={classes.input} placeholder="Filter Data" inputProps={{ 'aria-label': 'Filter Data' }} />
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item md={12}>
                    <div className={classes.lastUpdate}>
                        <Grid container>
                            <Grid item md={1}>
                                <Grid color="inherit" style={{fontFamily:'Roboto'}}>Last Updated : </Grid>
                            </Grid>
                            <Grid item md={2}>
                                <Grid className={classes.mainTitle}>Today</Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item md={12}>
                    <div className={classes.average}>
                        <Grid container>
                            <Grid item md={1}>
                                <Grid color="inherit" style={{fontFamily:'Roboto'}}>Average : </Grid>
                            </Grid>
                            <Grid  item md={11}>
                                {subProducts.map((subProduct ,index)=>{
                                    if(index < 1 && subProduct.productId === this.props.match.params.id){
                                        return(
                                            <div key={subProduct.id}>
                                                <span className={classes.mainTitle}><PersonPinCircleOutlinedIcon className={classes.location} /> Canada , Vancouver</span>
                                                <span color="inherit" className={classes.mainTitle} > <QueryBuilderIcon className={classes.location} /> {(subProduct.createdAt).slice(0, 10)} </span>
                                                <span color="inherit" className={classes.mainTitle} ><MonetizationOnOutlinedIcon className={classes.location} /> {subProduct.price} {subProduct.currency}</span>
                                            </div>
                                        )
                                    }
                                })}
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item md={12}>
                    <div className={classes.imageSection}>
                        <Grid container>
                            <Grid item md={4}>
                                <Paper className={classes.imageOne}  elevation={3}>
                                    {products.map((product)=>{
                                        if(product.id === this.props.match.params.id){
                                             return(
                                                <img key={product.id}  className={classes.backDown} src={product.image} />
                                              )
                                         }
                                     })}
                                </Paper>
                            </Grid>
                            <Grid item md={4}>
                                <Paper className={classes.imageTwo}  elevation={3}>
                                    {results.length == 0 ? 
                                        subProducts.map((subProduct ,index)=>{
                                            if(index < 1 && subProduct.productId === this.props.match.params.id){
                                                return(
                                                    <img key={subProduct.id}  className={classes.backDown} src={subProduct.imageEntry} />
                                                )
                                            }
                                        }):
                                        results.map((subProduct ,index)=>{
                                            if(index < 1 && subProduct.productId === this.props.match.params.id){
                                                return(
                                                    <img key={subProduct.id}  className={classes.backDown} src={subProduct.imageEntry} />
                                                )
                                            }
                                        })
                                    }
                                </Paper>
                            </Grid>
                            <Grid item md={4}>
                                <Paper className={classes.imageThere}  elevation={3}>
                                    {results.length == 0 ?
                                        subProducts.map((subProduct,index)=>{
                                            if(index < 2 && subProduct.productId === this.props.match.params.id){
                                                return(
                                                    <img key={subProduct.id}  className={classes.backDown} src={subProduct.imageEntry} />
                                                )
                                            }
                                        }):
                                        subProducts.map((subProduct,index)=>{
                                            if(index < 2 && subProduct.productId === this.props.match.params.id){
                                                return(
                                                    <img key={subProduct.id}  className={classes.backDown} src={subProduct.imageEntry} />
                                                )
                                            }
                                        })
                                    }
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item md={12}>
                    <div className={classes.append}>
                        <Grid container className={classes.appendInput}>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" style={{backgroundColor:'#FCFCFC'}}>
                                    <Grid className={classes.accordionTitle}>+ Add My Data</Grid>
                                </AccordionSummary>
                                <AccordionDetails style={{backgroundColor:'#FCFCFC'}}>
                                    <Grid container>
                                        <form onSubmit={this.handleSubmit}>
                                            <Grid item md={12}>
                                                <FormControl  variant="outlined" className={classes.inputStyle}>
                                                    <InputLabel htmlFor="brand" style={{marginTop :'-3px' , fontFamily :'Roboto',}}>Brand</InputLabel>
                                                    <OutlinedInput  name="brand"  type="text" value={this.state.brand}  onChange={this.handleChange} labelWidth={45} error={errors.brand ? true : false} style={{height:'43px'}} />
                                                    <FormHelperText error={errors.brand ? true : false} className={classes.error}>{errors.brand}</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={12}>
                                                <FormControl variant="outlined" className={classes.inputStyle}>
                                                    <InputLabel id="unitsId" className={classes.labelInput}>Units</InputLabel>
                                                    <Select label="Units" labelId="unitsId" name="unitsEntry"  value={this.state.unitsEntry}  onChange={this.handleChange} error={errors.unitsEntry ? true : false} style={{height:'43px'}} >
                                                        <MenuItem value=""> <em>None</em> </MenuItem>
                                                        {subUnits.map(subUnit =>{
                                                            return(
                                                                products.map((product)=>{
                                                                    if(subUnit.unitId === product.units && product.id === this.props.match.params.id) {
                                                                        return(
                                                                            <MenuItem key={subUnit.id} value={subUnit.title}>{subUnit.title}</MenuItem>
                                                                        )
                                                                    }
                                                                })
                                                            )
                                                        })}
                                                    </Select>
                                                    <FormHelperText error={errors.unitsEntry ? true : false} className={classes.error}>{errors.unitsEntry}</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={12}>
                                                <FormControl  variant="outlined" className={classes.inputStyle}>
                                                    <InputLabel htmlFor="amount" className={classes.labelInput}> Enter The Amount</InputLabel>
                                                    <OutlinedInput name="amount"  type="number" value={this.state.amount}  onChange={this.handleChange} labelWidth={118} error={errors.amount ? true : false } style={{height:'43px'}} />
                                                    <FormHelperText error={errors.amount ? true : false} className={classes.error}>{errors.amount}</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={12}>
                                                <Grid container>
                                                    <Grid item sm={8}>
                                                        <FormControl className={classes.latPrice} variant="outlined">
                                                            <InputLabel htmlFor="latPrice" className={classes.labelInput}>last Price</InputLabel>
                                                            <OutlinedInput id="latPrice" name="price"  type="number" value={this.state.price}  onChange={this.handleChange} labelWidth={70} error={errors.price ? true : false} style={{height:'43px'}} />
                                                            <FormHelperText error={errors.price ? true : false} className={classes.error}>{errors.price}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item sm={4}>
                                                        <FormControl variant="outlined" className={classes.currency}>
                                                            <Autocomplete   onInputChange={this.handleInputChange} style={{height:'43px'}}  id="countries" autoHighlight options={countries} className={classes.option}  getOptionLabel={(option) => option.currency} renderOption={(option) => (
                                                                <React.Fragment>
                                                                    <div>
                                                                        <span className={classes.flag}>{countryToFlag(option.code)}</span>
                                                                        {option.currency}
                                                                    </div>
                                                                </React.Fragment>)}
                                                                            renderInput={(params) => (
                                                                                <TextField {...params}  label="Currency" variant="outlined" name="currency" inputProps={{ ...params.inputProps, autoComplete: 'new-password'}}  error={errors.currency ? true : false}   />
                                                                            )}/>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item md={12}>
                                                <FormControl  variant="outlined" className={classes.inputStyle}>
                                                    <Autocomplete multiple onChange={this.handleStore} className={classes.store} id="tags-filled" freeSolo
                                                        options={stores.map((option) => option.status === 'accept' && option.productId == this.props.match.params.id ? option.title.toString() : '')}

                                                        renderTags={(value, getTagProps) =>
                                                            value.map((option, index) => (
                                                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                                            ))
                                                        }
                                                        renderInput={(params) => (
                                                            <TextField value={store} name="store"  onKeyDown={(event) => this.handleConcatStore(event)}  {...params} variant="filled" label="Store" style={{fontFamily:'Roboto'}}  />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={12}>
                                                <FormControl  variant="outlined" className={classes.inputStyle}>
                                                    <Autocomplete multiple onChange={this.handleTag} className={classes.store} id="tags-filled" freeSolo
                                                       options={tags.map((option) => option.status === 'accept' && option.productId == this.props.match.params.id ? option.title.toString() : '')}

                                                        renderTags={(value, getTagProps) =>
                                                            value.map((option, index) => (
                                                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                                            ))
                                                        }
                                                        renderInput={(params) => (
                                                            <TextField value={tag} name="tag"  onKeyDown={(event) => this.handleConcatTag(event)}  {...params} variant="filled" label="Tags"  />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={12}>
                                                <Grid container>
                                                    <Grid item md={11}>
                                                        <input accept="image/*" id="contained-button-file"  name="imageEntry"  type="file"  onChange={this.handleGetImage} style={{height:'43px' , display:'none'}} />
                                                        {imageEntry == '' ?
                                                            <label htmlFor="contained-button-file"><Button variant="contained" style={{width:1044, height:43}} component="span" startIcon={<CloudUploadIcon />}>{beforeImage == '' ? 'Upload Photo' : 'Please upload the selected Photo'}</Button></label> :
                                                            <label htmlFor="contained-button-file"><Button variant="contained" style={{width:1044 , height:43}} color="secondary" component="span" startIcon={<CloudUploadIcon />}>Uploaded Photo</Button></label>
                                                        }
                                                    </Grid>
                                                    <Grid item md={1}>
                                                        <Button className={classes.addPhoto}>
                                                            <AddAPhotoOutlinedIcon />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item md={12}>
                                                <Grid container>
                                                    <Grid item md={6}>
                                                        <Button onClick={this.handleUpload} className={classes.imageProduct}>Upload Photo</Button>
                                                    </Grid>
                                                    <Grid item md={6}>
                                                        <Button type="submit" className={classes.createProduct}>Create Product</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </div>
                </Grid>
                <Grid item md={12}>
                    <div className={classes.chartSection}>
                        <Grid container>
                            <Grid item md={3}>
                                <Grid color="inherit" className={classes.RightText}>Dimension : Time</Grid>
                            </Grid>
                            <Grid item md={5}>
                                <Grid color="inherit" className={classes.LeftText} >Metrics : Price</Grid>
                            </Grid>
                            <Grid item md={4}>
                                <Grid color="inherit" className={classes.LeftText} >Unit : Liter</Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item md={12}>
                                <img className={classes.chart} src={chart} />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item md={12}>
                    <div className={classes.allPrice}>
                        <Grid container>
                            <Grid item md={12}>
                                <div className={classes.tableDistance}>
                                    <TableContainer component={Paper} className={classes.table}>
                                        <Table className={classes.table} aria-label="customized table">
                                            <TableHead>
                                                <TableRow hover>
                                                    <StyledTableCell align="center">Brand</StyledTableCell>
                                                    <StyledTableCell align="center">Date</StyledTableCell>
                                                    <StyledTableCell align="center">Price</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {results.length == 0 ? 
                                                    subProducts.map((subProduct)=> {
                                                        if(subProduct.productId === this.props.match.params.id){
                                                            return(
                                                                <StyledTableRow key={subProduct.id} hover>
                                                                    <StyledTableCell align="center"> {subProduct.brand}</StyledTableCell>
                                                                    <StyledTableCell align="center">{(subProduct.createdAt).slice(0, 10)}</StyledTableCell>
                                                                    <StyledTableCell align="center">{subProduct.price} {subProduct.currency}</StyledTableCell>
                                                                </StyledTableRow>
                                                            )
                                                        }
                                                    }) :

                                                    results.map((subProduct)=> {
                                                        if(subProduct.productId === this.props.match.params.id){
                                                            return(
                                                                <StyledTableRow key={subProduct.id} hover>
                                                                    <StyledTableCell align="center"> {subProduct.brand}</StyledTableCell>
                                                                    <StyledTableCell align="center">{(subProduct.createdAt).slice(0, 10)}</StyledTableCell>
                                                                    <StyledTableCell align="center">{subProduct.price} {subProduct.currency}</StyledTableCell>
                                                                </StyledTableRow>
                                                            )
                                                        }
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        )
    }
}



/* ------------------------------------------------ Database Connection --------------------------------------------*/
details.propTypes ={
    classes       : PropTypes.object.isRequired,
    singleProduct : PropTypes.func.isRequired,
    ui            : PropTypes.object.isRequired,
    product       : PropTypes.object.isRequired,
    productId     : PropTypes.string.isRequired,

    getCategories : PropTypes.func.isRequired,
    getSubCategories : PropTypes.func.isRequired,
    getUnits      : PropTypes.func.isRequired,
    getSubUnits      : PropTypes.func.isRequired,
    getStores       : PropTypes.func.isRequired,
    getTags        : PropTypes.func.isRequired,

    categories    : PropTypes.array.isRequired,
    subCategories : PropTypes.array.isRequired,
    units         : PropTypes.array.isRequired,

    getProducts   : PropTypes.func.isRequired,
    getSubProducts : PropTypes.func.isRequired,
    products      : PropTypes.array.isRequired,
}



const mapStateToProps = (state)=> ({
    categories : state.category.categories,
    subCategories : state.category.subCategories,
    units       : state.category.units,
    subUnits       : state.category.subUnits,
    ui          : state.ui,

    products      : state.data.products,
    stores        : state.data.stores,
    tags          : state.data.tags,
    subProducts   : state.data.subProducts,
    product     : state.data.product
})



const mapActionsToProps = {
    getStores,
    getTags,
    getSubUnits,
    getProducts,
    getSubProducts,
    singleProduct,
    getCategories ,
    getSubCategories,
    getUnits,
}




/* --------------------------------------------------- Styles Component ----------------------------------------------*/
const styles = (theme) => ({
    root: {
        flexGrow: 1,
        marginBottom : theme.spacing(7)
    },

    breadcrumb : {
        margin : '3.5% 0 0 7.5%',
        color : '#403232',
        fontWeight : '500',
        fontSize :'17px',
        fontFamily : 'Roboto'
    },

    selectedBreadcrumb : {
        color : '#0D85E5',
        fontFamily : 'Roboto'
    },

    lastUpdate :{
        margin : '4% 0 0 7.5%',
        color : '#403232',
        fontWeight : '700',
        fontFamily : 'Roboto'
    },

    average :{
        margin : '2.4% 0 0 7.5%',
        color : '#403232',
        fontWeight : '700',
        fontFamily : 'Roboto'
    },

    mainTitle :{
        color : '#717171',
        fontWeight : '500',
        fontSize : 17,
        fontFamily : 'Roboto',
        marginRight : 60
    },

    location: {
        color : '#717171',
        fontSize :'18px',
        margin:'0 6px -3px 0'
    },


    imageSection : {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin:'2% 2.2% 0 7.4%' ,
        },
    },

    backDown :{
        filter: 'brightness(60%)',
        width : '102%' ,
        height :'102%',
    },

    imageOne : {
        width : 435 ,
        height : 240
    },

    imageTwo :{
        width : 435 ,
        height : 240,
        margin:'0 0 0 12%' ,
    },

    imageThere :{
        width : 232 ,
        height : 117.8,
        margin:'0 0 0 24%' ,
    },

/* ==================== Accordion ===================*/
    append:{
        margin : '4% 2.2% 0 7.6%'
    },

    appendInput : {
        margin : '3.3% 0 0 0',

    },

    accordionTitle:{
        color : '#403232',
        fontWeight : '600',
        fontSize :'16px',
        fontFamily : 'Roboto'
    },

    inputStyle :{
        width : '100%',
        backgroundColor: '#FAFAFA',
        borderColor :'#E6E6E6',
        margin :'0 0 1.8% 0'
    },

    labelInput:{
        marginTop : '-6px',
        fontFamily :'Roboto'
    },

    latPrice: {
        width : '105%',
        backgroundColor: '#FAFAFA',
        borderColor :'#E6E6E6',
        margin :'0 0 3% 0'
    },

    currency : {
        width : '84.8%',
        backgroundColor: '#FAFAFA',
        borderColor :'#E6E6E6',
        margin :'0 0 0 15%'
    },

    uploadPhoto :{
        width : '374%',
        backgroundColor: '#FAFAFA',
        borderColor :'#E6E6E6',
        margin :'0 0 4% 0'
    },


    addPhoto:{
        margin :'0px 0px 0% 31%',
        height:45,
        border :'1px solid #C6C6C6'
    },

    createProduct:{
        width :251,
        margin :'8% 0px 4% 0.8%',
        textTransform :'none',
        backgroundColor:'#0D85E5',
        color:'white',
        fontFamily :'Roboto',
        "&:hover": {
            color : 'black'
        },
    },

    imageProduct:{
        width :251,
        margin :'8% 0px 4% 49%',
        textTransform :'none',
        backgroundColor:'#0D85E5',
        color:'white',
        fontFamily :'Roboto',
        "&:hover": {
            color : 'black'
        },
    },


/* ==================== Chart ===================*/
    chartSection :{
        margin : '7% 2% 0 7.8%',
    },

    RightText: {
        fontFamily:'Roboto',
        color : '#717171',
        fontWeight : 700
    },

    LeftText: {
        marginLeft : '27%',
        fontFamily:'Roboto',
        color : '#717171',
        fontWeight : 700
    },

    chart : {
        margin : '6% 0 0 0.2%',
        width:'93%'
    },

/* ==================== Table ===================*/
    allPrice :{
        margin : '4% 0 0 6%'
    },

    table: {
        width:1138,
    },

    tableDistance :{
        margin : '3% 0 0 1.5%'
    }

});

export default connect(mapStateToProps , mapActionsToProps)(withStyles(styles)(details))
