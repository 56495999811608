/* -------------------------------------------- Mouduls and Component -----------------------------------*/
/* ================== General Mouduls ======================*/
import React, { Component , Fragment} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux'
import PropTypes from 'prop-types';

/* ================== Exclusive Mouduls ======================*/
import AppBar from '@material-ui/core/AppBar';
import ToolBar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Logo from '../../../images/logo.png';
import {logoutUser} from '../../../store/actions/userAction'
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography'
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import AvatarProfile from '../../../images/avatar.png';


/* ------------------------------------------------- Render Component ----------------------------------------------*/
class header extends Component {
    constructor(props){
        super(props);
        this.state = {
         
        }
    }


/* ========================== Dedicated Methods ===============================*/
    handleLogout =() => {
        this.props.logoutUser()
    }



/* ========================== Render Component ===============================*/
    render() {
        const {classes ,authenticated , user : {credentials : {handle , createdAt , imageUrl , phone , biography} , loading}} = this.props

        return (
            <div className={classes.root}>
                 <AppBar position="static" className={classes.mainNavBar} elevation={0}>
                    <ToolBar style={{minHeight:0}}>
                        {authenticated ? (
                            <Fragment>
                                <RoomOutlinedIcon className={classes.location} />
                                <Typography className={classes.textLocation}>Canada , Vancouver</Typography>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <RoomOutlinedIcon className={classes.location} />
                                <Typography className={classes.textLocation}>Canada , Vancouver</Typography>
                            </Fragment>
                        )}
                    </ToolBar>
                </AppBar>

                <AppBar position="static" className={classes.subNavBar} elevation={0}>
                    <ToolBar>
                        <Avatar component={Link} to='/' alt="Data By Pepople Logo" src={Logo} className={classes.logo}></Avatar>
                        <Button color="inherit" className={classes.homeButton} component={Link} to='/'>Home</Button>
                        <Button  color="inherit" className={classes.menuButton} component={Link} to='/compare'>Compare</Button>
                        <Button  color="inherit" className={classes.menuButton} component={Link} to='/favourites'>Favourites</Button>
                        <Button  color="inherit" className={classes.dataButton} component={Link} to='/myData'>My Data</Button>
                        <Button component={Link} to='/search' className={classes.searchIcon}><SearchIcon style={{fontSize:26}} /></Button>

                        {authenticated ? (
                            <Fragment>
                                <Avatar alt="Avatar" src={AvatarProfile} className={classes.avatar} component={Link} to='/profile'></Avatar>
                                <Button  className={classes.addData} component={Link} to='/search'> + Enter Data</Button>
                                <Button  color="inherit" className={classes.logOut} onClick={this.handleLogout}>Logout</Button>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Button  color="inherit" className={classes.menuButton} component={Link} to='/search'>Enter Data</Button>
                                <Button  color="inherit" className={classes.loginButton} component={Link} to='/login'>Login</Button>
                                <Button  color="inherit" className={classes.menuButton} component={Link} to='/signUp'>SignUp</Button>
                            </Fragment>
                        )}
                    </ToolBar>
                </AppBar>
            </div>
        )
    }
}


/* ------------------------------------------------ Database Connection --------------------------------------------*/
header.propTypes ={
    classes    : PropTypes.object.isRequired,
    logoutUser : PropTypes.func.isRequired,
    user       : PropTypes.object.isRequired,
    authenticated : PropTypes.bool.isRequired,
}

const mapStateToProps = (state)=> ({
    user : state.user,
    authenticated : state.user.authenticated
})

const mapActionsToProps = {
    logoutUser
}


/* --------------------------------------------------- Styles Component ----------------------------------------------*/
const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },

    mainNavBar :{
        background : '#FAFAFA',
        height: 40,
        color : '#717171',
        boxShadow : 'none',
    },

    textLocation :{
        color : '#393636',
        margin : '9px 0 0 1%',
        fontSize :'15px',
        fontFamily: 'Roboto',
    },

    location: {
        color : '#0d85e5',
        fontSize :'21px',
        margin : '9px 0 0 5.3%',
    },


    subNavBar : {
        background : 'white',
        height: 60,
        color : '#717171',
        padding: '12px 0 70px 0',
        boxShadow: '0px 15px 17px 1px #FAFAFA'
    },

    logo : {
        marginLeft :'5.5%',
        marginRight :'2.3%',
        width: "80px",
        height: "48px",
    },

    homeButton :{
        marginLeft : '3.7%',
        marginRight:27,
        fontWeight : '800',
        textTransform :'none',
        letterSpacing :'none',
        "&:hover": {
            background: "white"
        },
    },

    menuButton: {
        padding: '0 27px 0 0',
        fontWeight : '800',
        textTransform :'none',
        letterSpacing :'none',
        "&:hover": {
            background: "white"
        },
    },

    dataButton: {
        margin: '0 12% 0 0',
        fontWeight : '800',
        textTransform :'none',
        letterSpacing :'none',
        "&:hover": {
            background: "white"
        },
    },

    searchIcon: {
        margin: '0 1% 0 0',
        color : '#7E7E7E',
        height: "37px",
        "&:hover": {
            background: "white"
        },
    },

    avatar : {
        width: "34px",
        height: "34px",
        borderRadius : '50%',
        border: '1px solid #0d85e5',

    },

    loginButton : {
        textTransform :'none',
        letterSpacing :'none',
        marginRight: '2%',
        marginLeft: '8%',
        fontWeight : '800',
        "&:hover": {
            background: "white"
        },
    },

    logOut :{
        textTransform :'none',
        letterSpacing :'none',
        fontWeight : '800',
        "&:hover": {
            background: "white"
        },
    },

    addData : {
        textTransform :'none',
        background : '#0d85e5',
        color : 'white',
        width: 130,
        height: "32px",
        marginLeft: '10.1%',
        marginRight: '1%',
        fontFamily: 'Roboto',
        borderRadius: 16
    },

    title: {
        flexGrow: 1,
    },
});


export default connect(mapStateToProps , mapActionsToProps)(withStyles(styles)(header))
