/* ----------------------------------------- Mouduls and Component -------------------------------*/
import React, { Component } from 'react';





class panel extends Component {
    render(){
        return(
            <h1 style={{textAlign:'center' , margin :'180px 0 180px 0'}}>Panel Management</h1>
        )
    }
}




export default panel;