/* ----------------------------------------- Category Reducers Types -------------------------------*/
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const CREATE_CATEGORY  = 'CREATE_CATEGORY'
export const SET_CATEGORY = 'SET_CATEGORY'

export const SET_SUB_CATEGORIES = 'SET_SUB_CATEGORIES'
export const CREATE_SUB_CATEGORY  = 'CREATE_SUB_CATEGORY'
export const SET_SUB_CATEGORY = 'SET_SUB_CATEGORY'

export const SET_UNITS = 'SET_UNITS'
export const CREATE_UNIT  = 'CREATE_UNIT'
export const SET_UNIT = 'SET_UNIT'
export const SET_SUB_UNITS = 'SET_SUB_UNITS'


/* ----------------------------------------- Data Reducers Types -------------------------------*/
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED'
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED'
export const SET_USER = 'SET_USER'
export const LOADING_USER = 'LOADING_USER'


/* ----------------------------------------- Ui Reducers Types -------------------------------*/
export const SET_ERRORS = 'SET_ERRORS'
export const LOADING_UI = 'LOADING_UI'
export const STOP_LOADING_UI = 'STOP_LOADING_UI'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const LOADING_DATA = 'LOADING_DATA'


/* ----------------------------------------- Product and Stores Reducers Types -------------------------------*/
export const SET_PRODUCTS = 'SET_PRODUCTS'
export const SET_PRODUCT  = 'SET_PRODUCT'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const CREATE_SUB_PRODUCT = 'CREATE_SUB_PRODUCT'
export const SET_SUB_PRODUCTS = 'SET_SUB_PRODUCTS'
export const SEARCH_PRODUCT = 'SEARCH_PRODUCT'
export const SET_STORES = 'SET_STORES'
export const SET_TAGS = 'SET_TAGS'