import {CREATE_CATEGORY , SET_ERRORS , CLEAR_ERRORS , LOADING_UI , LOADING_DATA,SET_CATEGORY , SET_CATEGORIES ,STOP_LOADING_UI, SET_SUB_CATEGORIES,SET_SUB_UNITS, CREATE_SUB_CATEGORY, SET_UNITS, CREATE_UNIT} from '../types'
import axios from 'axios'
import Swal from "sweetalert2";

let Toast = Swal.mixin({
    toast: true,
    position: 'bottom-start',
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})



/* -----------------------------------------  Category Actions -----------------------------------*/

export const getCategories = () => (dispatch) =>{

    dispatch({type : LOADING_DATA})

    axios.get('/categories').then((res)=>{

        dispatch({
            type : SET_CATEGORIES,
            payload : res.data
        })

    }).catch((err) =>{
        dispatch({
            type : SET_CATEGORIES,
            payload :[]
        })
    })
}




export const singleCategory = (categoryId) => (dispatch) => {
    dispatch({type : LOADING_UI})

    axios.get(`/category/${categoryId}`).then((res)=>{

        dispatch({
            type : SET_CATEGORY,
            payload : res.data
        })

        dispatch({
            type : STOP_LOADING_UI,
        })

    }).catch((err) =>{
        console.log(err)
    })
}



export const createCategory = (newCategory) => (dispatch) =>{

    dispatch({type : LOADING_UI})

    axios.post('/category/create' , newCategory).then((res)=>{

        dispatch({
            type : CREATE_CATEGORY,
            payload : res.data
        })

        Toast.fire({
            icon: 'success',
            title: 'Category Create Successfully'
        })

        dispatch({
            type : CLEAR_ERRORS,
        })

    }).catch((err) =>{
        dispatch({
            type : SET_ERRORS,
            payload : err.response.data
        })

        Toast.fire({
            icon: 'info',
            title: 'Please fill in the fields completely'
        })
    })
}


/* ---------------------------------------- sub Category Actions --------------------------------*/

export const getSubCategories = () => (dispatch) =>{

    dispatch({type : LOADING_DATA})

    axios.get('/subCategories').then((res)=>{

        dispatch({
            type : SET_SUB_CATEGORIES,
            payload : res.data
        })

    }).catch((err) =>{
        dispatch({
            type : SET_SUB_CATEGORIES,
            payload :[]
        })
    })
}



export const createSubCategory = (newSubCategory) => (dispatch) =>{

    dispatch({type : LOADING_UI})

    axios.post('/subCategory/create' , newSubCategory).then((res)=>{

        dispatch({
            type : CREATE_SUB_CATEGORY,
            payload : res.data
        })

        Toast.fire({
            icon: 'success',
            title: 'subCategory Create Successfully'
        })

        dispatch({
            type : CLEAR_ERRORS,
        })

    }).catch((err) =>{
        dispatch({
            type : SET_ERRORS,
            payload : err.response.data
        })

        Toast.fire({
            icon: 'info',
            title: 'Please fill in the fields completely'
        })
    })
}




/* -------------------------------------------- Units Actions ----------------------------------*/

export const getUnits = () => (dispatch) =>{

    dispatch({type : LOADING_DATA})

    axios.get('/units').then((res)=>{

        dispatch({
            type : SET_UNITS,
            payload : res.data
        })

    }).catch((err) =>{
        dispatch({
            type : SET_UNITS,
            payload :[]
        })
    })
}



export const createUnit = (newUnit) => (dispatch) =>{

    dispatch({type : LOADING_UI})

    axios.post('/unit/create' , newUnit).then((res)=>{

        dispatch({
            type : CREATE_UNIT,
            payload : res.data
        })

        dispatch({
            type : CLEAR_ERRORS,
        })

    }).catch((err) =>{
        dispatch({
            type : SET_ERRORS,
            payload : err.response.data
        })
    })
}


export const getSubUnits = () => (dispatch) =>{

    dispatch({type : LOADING_DATA})

    axios.get('/subUnits').then((res)=>{

        dispatch({
            type : SET_SUB_UNITS,
            payload : res.data
        })

    }).catch((err) =>{
        dispatch({
            type : SET_SUB_UNITS,
            payload :[]
        })
    })
}