import {createStore ,combineReducers , applyMiddleware} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk'
import userReducer from './reducers/userReducer'
import dataReducer from './reducers/dataReducer'
import uiReducer from './reducers/uiReducer'
import categoryReducer from './reducers/categoryReducer'

const initiaState = {} 
const middleware = [thunk]

const reducers = combineReducers({
    user     : userReducer ,
    category : categoryReducer,
    data     : dataReducer ,
    ui       : uiReducer
})


const store = createStore(reducers , initiaState , composeWithDevTools(applyMiddleware(...middleware)))


export default store



