/* ----------------------------------------- Mouduls and Component -------------------------------*/
import React, { Component } from 'react';
import './styles/general/general.css'
import {BrowserRouter as Router ,Route , Switch} from 'react-router-dom'
import home from './components/general/home'
import login from './components/general/auth/login'
import signUp from './components/general/auth/signUp'
import Header from './components/general/layout/header'
import Footer from './components/general/layout/footer'
import details from './components/general/products/details'
import createCategory from './components/general/categories/createCategory'
import createSubCategory from './components/general/categories/createSubCategory'
import categories from './components/general/products/create/step1'
import subCategories from './components/general/products/create/step2'
import units from './components/general/products/create/step3'
import addData from './components/general/products/create/step5'
import success from  './components/general/products/create/step6'
import favourites from './components/general/favourites'
import compare from './components/general/compare'
import myData from './components/general/myData'
import upload from './components/general/products/create/step4'
import search from './components/general/products/search'
import profile from './components/management/userProfile'

import panel from './components/management/panel'
import AuthRoute from './config/authRoute'
import jwtDecode from 'jwt-decode'
import {Provider} from 'react-redux'
import {SET_AUTHENTICATED} from './store/types'
import {logoutUser , getUserData} from './store/actions/userAction'
import store from './store/store'
import axios from 'axios'



/* ---------------------------------------------- Render Views --------------------------------------*/


const token = localStorage.idToken

if(token) {
  const decodeToken = jwtDecode(token)

  if(decodeToken * 50000 < Date.now()){
    store.dispatch(logoutUser())
    window.location.href = '/login'
  }else {
    store.dispatch({type : SET_AUTHENTICATED})
    axios.defaults.headers.common['Authorization'] = token
    store.dispatch(getUserData())
  }
}
class App extends Component{
  render(){
    return (
      <Provider store={store}>
          <Router>
            <Header/>
              <Switch>
                <Route exact path='/' component={home} />
                <AuthRoute exact path='/login' component={login}  />            
                <AuthRoute exact path='/signUp' component={signUp} />
                <Route exact path='/profile' component={profile} />
                
                <Route exact path='/product/:id' component={details}></Route>
                <Route exact path='/favourites/' component={favourites}></Route>
                <Route exact path='/compare/' component={compare}></Route>
                <Route exact path='/myData/' component={myData}></Route>
                <Route exact path='/search/' component={search}></Route>

                <Route exact path='/createProduct/categories/:id' component={categories}></Route>
                <Route exact path='/createCategory/:id' component={createCategory}></Route>

                <Route exact path='/createProduct/subCategories/:id/:categoryId' component={subCategories}></Route>
                <Route exact path='/createSubCategory/' component={createSubCategory}></Route>

                <Route exact path='/createProduct/units/:id/:subCategoryId' component={units}></Route>

                <Route exact path='/createProduct/upload/:id' component={upload}></Route>
                <Route exact path='/createProduct/addData/:id' component={addData}></Route>
                <Route exact path='/createProduct/success/:id' component={success}></Route>

                <AuthRoute exact path='/management/panel' component={panel} />
              </Switch>
            <Footer />
          </Router>
      </Provider>
    );
  }
}


export default App;
