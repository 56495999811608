/* -------------------------------------------- Mouduls and Component -----------------------------------*/
/* ================== General Mouduls ======================*/
import React, { Component } from 'react';
import {Grid} from "@material-ui/core";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link as Router} from 'react-router-dom'
import {withStyles} from "@material-ui/core/styles";
import {clearErrors, getProducts} from "../../../../store/actions/dataAction";

/* ================== Exclusive Mouduls ======================*/
import Link from "@material-ui/core/Link";
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import uploadIcon from './../../../../images/uploadIcon.png'
import {storage} from "../../../../config/config";
import FormHelperText from "@material-ui/core/FormHelperText";
import axios from "axios";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

import Swal from 'sweetalert2'
let Toast = Swal.mixin({
    toast: true,
    position: 'bottom-start',
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})


/* ------------------------------------------------- Render Component ----------------------------------------------*/
class upload extends Component {
    constructor(props){
        super(props);
        this.state = {
            image         : '' ,
            beforeImage   : '',
            errors        : {},
        }
    }


/* ========================== Dedicated Methods ===============================*/
    componentDidMount(){
        this.props.getProducts()
    }


    handleGetImage = (event) =>{
        if(event.target.files[0]){
            this.setState({
                beforeImage : event.target.files[0]
            })
        }
    }



    handleUpload = () => {
        const uploadTask =storage.ref(`products/${this.state.beforeImage.name}`).put(this.state.beforeImage)
        uploadTask.on("state_changed" , snapshot => {} , error => {console.log(error)} , () => {
            storage.ref('products').child(this.state.beforeImage.name).getDownloadURL().then((url) => {
                this.setState({
                    image : url,
                })
            }).then(()=>{
                Toast.fire({
                    icon: 'success',
                    title: 'Upload Product Image Successfully'
                })
            })
        })
    }


    updateImage = (event) =>{
        event.stopPropagation();
        if(this.state.image !== ''){
            axios.put('/product/update/'+this.props.match.params.id ,{
                image : this.state.image ,
            }).then((res)=>{
                window.location.href = `/createProduct/addData/${this.props.match.params.id}`
            }).catch((err) =>{
                Toast.fire({
                    icon: 'error',
                    title: 'There is a Problem . please check it'
                })
                console.log(err)
            })
        }else{
            Toast.fire({
                icon: 'warning',
                title: 'Please select a photo for this product'
            })
        }
    }


    render(){
        const { classes, ui: {loading} , products} = this.props;
        const {errors}   = this.state

        return(
            <Grid container className={classes.root}>
                <Grid item md={12}>
                    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                        <Link color="inherit" href='/'>
                            Home
                        </Link>
                        <Link color="inherit" href="/search">
                            Search Or Create
                        </Link>
                        <Typography color="textPrimary">
                            {products.map((product)=>{
                                return(
                                    <div key={product.id}>
                                        {product.id === this.props.match.params.id ? <Link component={Router} to={`/createProduct/subCategories/${this.props.match.params.id}/${product.categoryId }`} style={{color : '#403232' , fontFamily :'Roboto'}}>{product.categoryId}</Link> : ''}
                                    </div>
                                )
                            })}
                        </Typography>
                        <Typography color="textPrimary" className={classes.selectedBreadcrumb}>
                            {products.map((product)=>{
                                return(
                                    <div key={product.id}>
                                        {product.id === this.props.match.params.id ? <Link component={Router} to={`/createProduct/units/${this.props.match.params.id}/${product.subCategoryId }`} style={{color : '#403232' , fontFamily :'Roboto'}}>{product.subCategoryId}</Link> : ''}
                                    </div>
                                )
                            })}
                        </Typography>
                        <Typography color="textPrimary" className={classes.selectedBreadcrumb}>
                            {products.map((product)=>{
                                return(
                                    <div key={product.id}>
                                        {product.id === this.props.match.params.id ? product.units : ''}
                                    </div>
                                )
                            })}
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item md={12}>
                    <img src={uploadIcon} className={classes.uploadIcon}/>
                    <Typography  className={classes.carouselText}>
                        {!this.state.beforeImage ? <FormHelperText className={classes.carouselText} error={errors.image ? true : false}>Upload Product Photo</FormHelperText> : <p style={{color : 'orangered' , fontFamily:'Roboto'}}>Please upload the selected Photo</p>}
                    </Typography>
                </Grid>
                <Grid item md={12}>
                    <div className={classes.boxUpload} style={{backgroundImage: `url(${this.state.image})`}}>
                        <input accept="image/*" className={classes.input} id="icon-button-file" type="file" onChange={this.handleGetImage} />
                        <label htmlFor="icon-button-file">
                            <Typography className={classes.photoText}>Select Photo</Typography>
                        </label>
                        {!this.state.beforeImage ? '' : <Button className={classes.uploadPhotoButton} onClick={this.handleUpload}>Upload Photo</Button>}
                    </div>
                </Grid>
                <Grid item md={12}>
                    <Grid container>
                        <Grid item md={6}>
                            {products.map((product)=>{
                                return(
                                    <div key={product.id}>
                                        {product.id === this.props.match.params.id ?
                                            <Link component={Router} to={`/createProduct/units/${this.props.match.params.id}/${product.subCategoryId }`}><Typography className={classes.backText}>
                                                <KeyboardBackspaceIcon style={{margin :'-7px 8px -7px 0px'}} />
                                                Back
                                            </Typography></Link>
                                            : ''
                                        }
                                    </div>
                                )
                            })}
                        </Grid>
                        <Grid item md={6}>
                            <Link onClick={this.updateImage}><Typography className={classes.forwardText} onClick={this.handleUpload}>
                                Next
                                <ArrowForwardIcon style={{margin :'-7px 0px -7px 12px'}} />
                            </Typography></Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}



/* ------------------------------------------------ Database Connection --------------------------------------------*/

upload.propTypes ={
    getProducts   : PropTypes.func.isRequired,
    classes       : PropTypes.object.isRequired,
    clearErrors   : PropTypes.func.isRequired,
    ui            : PropTypes.object.isRequired,
    products      : PropTypes.array.isRequired,
}




const mapStateToProps = (state)=> ({
    products      : state.data.products,
    ui            : state.ui,
})



const mapActionsToProps = {
    getProducts,
    clearErrors
}



/* --------------------------------------------------- Styles Component ----------------------------------------------*/
const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },

    breadcrumb : {
        margin : '3.5% 0 0 7.7%',
        color : '#403232',
        fontWeight : '500',
        fontSize :'16px',
        fontFamily : 'Roboto'
    },

    selectedBreadcrumb : {
        color : '#0D85E5',
        fontFamily : 'Roboto'
    },

    uploadIcon:{
        margin : '5% 0 0 42%'
    },

    mainTitle :{
        color : '#717171',
        fontWeight : '500',
        fontFamily :'Roboto'
    },

    carouselText :{
        textAlign :'center',
        fontFamily:'Roboto',
        margin : '2.6% 0 1.2% 0',
        fontWeight : '700',
        fontSize : 18,
        color : '#403232'
    },

    boxUpload :{
      width : 500 ,
      height: 190 ,
      border : '1.5px dashed #0D85E5',
      borderRadius : 9 ,
      backgroundColor : 'rgba(13 , 133 , 229 , 10%)',
      margin :'2.2% 0 3% 32%',
      backgroundRepeat:'no-repeat'
    },

    photoText :{
        color : '#403232',
        fontSize : 17,
        margin :'75px 0 0 40%',
        cursor: 'pointer',
        fontFamily:'Roboto',
        "&:hover": {
            color : 'white',
        },
    },

    uploadPhotoButton:{
        width: 200 ,
        textTransform :'none',
        background: "#0D85E5",
        color : 'white',
        fontFamily : 'Roboto',
        fontSize: 16 ,
        margin : '35px 0 0 30%',
        "&:hover": {
            background: "green",
            color : 'white',
        },
    },

    input: {
        display: 'none',
    },


    backText:{
        textAlign : 'left',
        fontFamily :'Roboto',
        margin : '3.2% 0 0 15.2%',
        color : '#0F2741',
        cursor: 'pointer',
        textDecoration :'none'
    },

    forwardText:{
        textAlign : 'right',
        fontFamily :'Roboto',
        margin : '3.2% 15.2% 0 0',
        color : '#0F2741',
        cursor: 'pointer',
        textDecoration :'none'
    }
});



export default connect(mapStateToProps , mapActionsToProps)(withStyles(styles)(upload));