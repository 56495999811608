const countries = [
    { code: 'US', label: 'United States', phone: '1',currency :'USD' ,suggested: true },
    { code: 'AU', label: 'Australia', currency: 'AUD', suggested: true },
    { code: 'CA', label: 'Canada', phone: '1',currency: 'CAD', suggested: true },
    { code: 'CH', label: 'Switzerland', phone: '41',currency: 'CHF' },
    { code: 'DE', label: 'Germany', phone: '49', suggested: true , currency:'ERU' },
    { code: 'FR', label: 'France', phone: '33', suggested: true, currency:'ERU' },
    { code: 'GB', label: 'United Kingdom', phone: '44',currency: 'GBP'},
    { code: 'HK', label: 'Hong Kong', phone: '852' , currency:'HKD '},
    { code: 'IL', label: 'Israel', phone: '972' , currency:'ILS' },
    { code: 'IN', label: 'India', phone: '91' , currency:'INR'},
    { code: 'IR', label: 'Iran, Islamic Republic of', phone: '98', currency:'IRR' },
    { code: 'JP', label: 'Japan', phone: '81', suggested: true , currency:'JPY' },
    { code: 'KR', label: 'Korea, Republic of', phone: '82', currency:'KRW' },
    { code: 'MY', label: 'Malaysia', phone: '60', currency:'MYR' },
    { code: 'NZ', label: 'New Zealand', phone: '64', currency:'NZD' },
    { code: 'RU', label: 'Russian Federation', phone: '7', currency:'RUB' },
    { code: 'SA', label: 'Saudi Arabia',currency :'SAR', phone: '966' },
    { code: 'TR', label: 'Turkey', currency :'TRY', phone: '90' },
];



module.exports = {countries} ;