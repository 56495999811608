import {SET_USER , SET_ERRORS , CLEAR_ERRORS , LOADING_UI , SET_UNAUTHENTICATED , LOADING_USER} from '../types'
import axios from 'axios'



export const loginUser = (userData , history) => (dispatch) =>{

    dispatch({type : LOADING_UI})

    axios.post('/login' , userData).then((res)=>{

        setAuthorization(res.data.token)
      
        dispatch(getUserData())

        dispatch({type : CLEAR_ERRORS})

        history.push('/')

    }).catch((err) =>{
        dispatch({
            type : SET_ERRORS,
            payload : err.response.data
        })
    })
}



export const getUserData =() => (dispatch) => {
    dispatch({type : LOADING_USER})
    axios.get('/userAuth').then((res)=>{
        dispatch({
            type : SET_USER,
            payload : res.data
        })
    }).catch((err) =>{
       console.error(err)
    })
}



export const signUpUser = (newUserData , history) => (dispatch) =>{

    dispatch({type : LOADING_UI})

    axios.post('/register' , newUserData ).then((res)=>{

        setAuthorization(res.data.token)

        dispatch(getUserData())

        dispatch({type : CLEAR_ERRORS})

        history.push('/')

    }).catch((err) =>{
        dispatch({
            type : SET_ERRORS,
            payload : err.response.data
        })
    })
}




const setAuthorization = (token) => {
    const idToken = `Bearer ${token}` ;
    localStorage.setItem('idToken' , idToken)
    axios.defaults.headers.common['Authorization'] = idToken
}




export const logoutUser = () => (dispatch) => {

    localStorage.removeItem('idToken')

    delete axios.defaults.headers.common['Authorization']

    dispatch({
        type : SET_UNAUTHENTICATED
    })
} 