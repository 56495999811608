import {
    LOADING_DATA,
    SET_CATEGORIES,
    CREATE_CATEGORY,
    SET_CATEGORY,
    SET_SUB_CATEGORIES,
    SET_UNITS,
    CREATE_SUB_CATEGORY,
    SET_SUB_UNITS
} from '../types'

const initiaState = {
    categories : [] ,
    subCategories : [],
    units : [],
    subUnits : [],

    category : {} ,
    subCategory : [],
    unit : [],

    loading : false
} 



export default function(state = initiaState , action){
    switch(action.type){
        case LOADING_DATA :
            return {
                ...state,
                loading : true
            };


        case SET_CATEGORIES:
             return {
                ...state,
                categories : action.payload ,
                loading : false
            };


        case SET_SUB_CATEGORIES:
             return {
               ...state,
               subCategories : action.payload ,
               loading : false
             };


         case SET_SUB_UNITS:
            return {
              ...state,
              subUnits : action.payload ,
              loading : false
            };

        case SET_UNITS:
            return {
                ...state,
                units : action.payload ,
                loading : false
            };

        case SET_CATEGORY:
            return {
                 ...state,
                category : action.payload
         };


        case CREATE_CATEGORY :
              return {
               ...state,
               categories : [
                   action.payload ,
                   ...state.categories
                ],
            };

        case CREATE_SUB_CATEGORY :
            return {
                ...state,
                subCategories : [
                    action.payload ,
                    ...state.subCategories
                ],
            };

        default :
            return state;
    }
}