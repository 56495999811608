/* -------------------------------------------- Mouduls and Component -----------------------------------*/
/* ================== General Mouduls ======================*/
import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import {getSubCategories , createCategory} from "../../../store/actions/categoryAction";
import {clearErrors ,  getProducts} from "../../../store/actions/dataAction";
import axios from "axios";
import {Link as Router} from "react-router-dom";

/* ================== Exclusive Mouduls ======================*/
import {Grid} from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import TextField from '@material-ui/core/TextField';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from 'sweetalert2'

let Toast = Swal.mixin({
    toast: true,
    position: 'bottom-start',
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})


/* ------------------------------------------------- Render Component ----------------------------------------------*/
class createCategories extends Component {
    constructor(props){
        super(props);

        this.state = {
            title             : '' ,
            description       : '' ,
            subCategoryTitle  : '' ,
            image             : '' ,
            subCategories     : [] ,
            errors            : {} ,
            generalSubCategory : '',
        }
    }


 /* ========================== Dedicated Methods ===============================*/
    componentDidMount(){
        this.props.getProducts()
        this.props.getSubCategories()
    }


    componentWillReceiveProps(nextProps){
        if(nextProps.ui.errors){
            this.setState({
                errors : nextProps.ui.errors
            })
        }

        if(!nextProps.ui.errors && !nextProps.ui.loading){
            this.setState({
                title             : '' ,
                description       : '',
                subCategoryTitle  : '',
                image             : '' ,
            })
        }
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name] : event.target.value,
        });
    }

    handleSubCategories = (value) => {
        this.setState({
            subCategories : this.state.subCategories.concat(value.target.textContent)
        })
    }

    handleConcatSubCategories = (event)=>{
        this.setState({
            [event.target.name] : event.target.value.trim(),
        });

        if(event.key === 'Enter'){
            event.preventDefault();
            this.state.subCategories.push(this.state.subCategoryTitle.trim())
        }
    }

    handleSubmit =(event) => {
        event.preventDefault();

        axios.post('/category/create' , this.state).then((res)=>{
            const {subCategoryTitle , subCategories , generalSubCategory}  = this.state

            /* ================== Create Suggestion subCategory ================*/
            if(subCategories.length < 1){
                const newSubCategory = {
                    title         : subCategoryTitle,
                    categoryId    : res.data.title,
                    description   : '',
                    image         : '',
                    status        : 'accept',
                };
                axios.post('/subCategory/create' , newSubCategory).then(()=>{}).catch((error) =>{console.log(error)})
            }else{
                for (const item of subCategories) {
                    if(item  !== ''){
                        const newSubCategories = {
                            title         : item,
                            categoryId    : res.data.title,
                            description   : '',
                            image         : '',
                            status        : 'accept',
                        };
                        axios.post('/subCategory/create' , newSubCategories).then(()=>{}).catch((error) =>{console.log(error)})
                    }
                }
            }

            /* ================== Create General subCategory ================*/
            if(generalSubCategory !== ''){
                const newGeneralSubCategory = {
                    title         : generalSubCategory,
                    categoryId    : res.data.title,
                    description   : '',
                    image         : '',
                    type          : 'general'
                };
                axios.post('/subCategory/create' , newGeneralSubCategory).then((res)=>{
                    axios.put('/product/update/'+this.props.match.params.id ,{
                        categoryId    : res.data.categoryId ,
                        subCategoryId : res.data.title
                    }).then((res)=>{
                        window.location.href = `/createProduct/units/${this.props.match.params.id}/${res.data.subCategoryId}`
                    }).catch((err) =>{console.log(err)})
                }).catch((error) =>{console.log(error)})
            }

        }).then(()=>{
            Toast.fire({
                icon: 'success',
                title: 'Create Category successfully'
            })
        }).catch((err) =>{
            Toast.fire({
                icon: 'error',
                title: 'Please fill in the fields carefully'
            })
            this.setState({
                errors : err.response.data
            })
        })

        this.props.clearErrors ()
    }


    cancelCategory =() =>{
        window.location.href = `/createProduct/categories/${this.props.match.params.id}`
    }

    render(){
        const {classes , ui : {loading} , subCategories , products} = this.props
        const {errors , subCategoryTitle , generalSubCategory}   = this.state

        return(
            <Grid container>
                <Grid item md={12}>
                    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                        <Link color="inherit" href='/'>
                            Home
                        </Link>
                        <Link color="inherit" href="/search">
                            Search Or Create
                        </Link>
                        <Grid  color="textPrimary">
                            {products.map((product)=>{
                                return(
                                    <div key={product.id}>
                                        {product.id === this.props.match.params.id ? <Link component={Router} to={`/createProduct/categories/${this.props.match.params.id}`} style={{color : '#403232' , fontFamily :'Roboto'}}>Choose the Categories</Link> : ''}
                                    </div>
                                )
                            })}
                        </Grid>
                        <Grid className={classes.selectedBreadcrumb}>
                                Create Category
                        </Grid>
                    </Breadcrumbs>
                </Grid>

                <Grid item md={12}>
                    <Grid className={classes.newCategory}>Enter a New Category </Grid>
                </Grid>

                <form noValidate onSubmit={this.handleSubmit} className={classes.formStyle}>
                    <Grid item sm={12}>
                        <FormControl variant="outlined" style={{marginBottom : 14}}>
                            <InputLabel htmlFor="title">Category Name</InputLabel>
                            <OutlinedInput className={classes.inputStyle} id="title" name="title"  type="text" value={this.state.title}  onChange={this.handleChange} labelWidth={110} error={errors.title ? true : false} />
                            <FormHelperText error={errors.title ? true : false}>{errors.title}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item sm={12}>
                        <FormControl variant="outlined" style={{marginBottom : 14}}>
                            <InputLabel htmlFor="description">Description(Optional)</InputLabel>
                            <OutlinedInput  className={classes.textInput} id="description" name="description"  type="text" value={this.state.description}  onChange={this.handleChange} labelWidth={148} error={errors.description ? true : false} />
                            <FormHelperText error={errors.description ? true : false}>{errors.description}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item sm={12}>
                        <FormControl variant="outlined" >
                            <InputLabel htmlFor="generalSubCategory">subCategory Name</InputLabel>
                            <OutlinedInput  className={classes.textInput} id="generalSubCategory" name="generalSubCategory"  type="text" value={generalSubCategory}  onChange={this.handleChange} labelWidth={148} error={errors.generalSubCategory ? true : false} />
                            <FormHelperText error={errors.generalSubCategory ? true : false}>{errors.generalSubCategory}</FormHelperText>
                        </FormControl>
                    </Grid>


                    <Grid item md={12}>
                        <Grid  className={classes.suggestedText}>Enter your suggested subcategories (optional) : </Grid>
                    </Grid>
                    <Grid item sm={12}>
                        <Autocomplete multiple onChange={this.handleSubCategories} className={classes.suggestInput} id="tags-filled" freeSolo
                              options={subCategories.map((option) => option.status === 'accept' ? option.title.toString() : '')}

                              renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                      <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                  ))
                              }
                              renderInput={(params) => (
                                  <TextField value={subCategoryTitle} name="subCategoryTitle"  onKeyDown={(event) => this.handleConcatSubCategories(event)}  {...params} variant="filled" label="subCategory Name" style={{fontFamily:'Roboto'}}  />
                              )}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <Grid container>
                            <Grid item sm={6}>
                                <Button type="submit" variant="contained" className={classes.createCategory} disabled={loading}>
                                    Create Category
                                    {loading && (
                                        <CircularProgress size={31} color="secondary" className={classes.progress} />
                                    )}
                                </Button>
                            </Grid>
                            <Grid item sm={6}>
                                <Button className={classes.cancelCategory} type="submit" variant="contained" onClick={this.cancelCategory}>
                                    Cancel
                                    {loading && (
                                        <CircularProgress size={31} color="secondary" className={classes.progress} />
                                    )}
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>
                </form>
            </Grid>
        )
    }
}




/* ------------------------------------------------ Database Connection --------------------------------------------*/
createCategories.propTypes ={
    classes        : PropTypes.object.isRequired,
    createCategory : PropTypes.func.isRequired,
    getProducts   : PropTypes.func.isRequired,

    getSubCategories : PropTypes.func.isRequired,
    clearErrors      : PropTypes.func.isRequired,

    subCategories : PropTypes.array.isRequired,
    ui            : PropTypes.object.isRequired
}




const mapStateToProps = (state)=> ({
    subCategories : state.category.subCategories,
    products      : state.data.products,
    ui            : state.ui,
})



const mapActionsToProps = {
    getProducts,
    getSubCategories,
    createCategory,
    clearErrors
}



/* --------------------------------------------------- Styles Component ----------------------------------------------*/
const styles = (theme) => ({
    breadcrumb : {
        margin : '3.5% 0 0 7.7%',
        color : '#403232',
        fontWeight : '500',
        fontSize :'16px',
        fontFamily : 'Roboto'
    },

    selectedBreadcrumb : {
        color : '#0D85E5',
        fontFamily : 'Roboto'
    },


    formStyle :{
        margin : '45px 0 0 423px'
    },

    newCategory :{
        textAlign :'center',
        fontFamily : 'Roboto',
        fontSize: 30,
        fontWeight: 800,
        margin : '5% 0 0% 0%'
    },

    inputStyle :{
        width :'262%',
        background : '#FAFAFA',
    },

    textInput :{
        width :'262%',
        background : '#FAFAFA',
    },

    suggestInput :{
        width :'113.6%',
        background : '#FAFAFA',
    },

    suggestedText:{
        fontFamily :'Roboto',
        fontWeight :'500',
        fontSize :17 ,
        margin:'5% 0 3% 0.3%'

    },

    createCategory:{
        backgroundColor :'#0D85E5',
        textTransform :'none',
        fontFamily :'Roboto',
        color : 'white',
        width :231,
        margin:'18% 0 20% 0'
    },

    cancelCategory:{
        backgroundColor :'rgba(13,133,229,5%)',
        textTransform :'none',
        fontFamily :'Roboto',
        color : '#0D85E5',
        width :231,
        margin:'18% 0 20% 26.4%',
        borderColor :'#0D85E5'
    }
});


export default connect(mapStateToProps , mapActionsToProps)(withStyles(styles)(createCategories))