/* -------------------------------------------- Mouduls and Component -----------------------------------*/
/* ================== General Mouduls ======================*/
import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Link as Route} from 'react-router-dom'
import {clearErrors, getProducts,searchProducts, getStores , getTags} from "../../../store/actions/dataAction";
import {getSubUnits} from "../../../store/actions/categoryAction";
import {connect} from 'react-redux'
import PropTypes from 'prop-types';
import {storage} from "../../../config/config";
import {countries} from '../../../config/countries'
import axios from 'axios'

/* ================== Exclusive Mouduls ======================*/
import Grid from '@material-ui/core/Grid';
import Link from "@material-ui/core/Link";
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from "@material-ui/core/InputBase";
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import searchProduct from '../../../images/search_product.png'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import AddAPhotoOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined';
import notFound from './../../../images/notFound.png'
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Swal from 'sweetalert2'
import Chip from "@material-ui/core/Chip";
let Toast = Swal.mixin({
    toast: true,
    position: 'bottom-start',
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})


/* ------------------------------------------------- Render Component ----------------------------------------------*/
class Search extends Component {

    constructor(props){
        super(props);
        this.state = {
            title         : '',

            brand         : '',
            unitsEntry    : '',
            price         : '',
            amount        : '',
            currency      : '',
            store         : '',
            resultStores  : [],
            tag           : '',
            resultTags    : [],
            beforeImage   : '',
            imageEntry    : '',
            productId     : '',

            errors        : {},
            results       : [],
            value         : 0 ,
            openEntry     : false,
            openUpload    : false,
        }
    }

 /* ========================== Dedicated Methods ===============================*/
    componentDidMount(){
        this.props.getSubUnits()
        this.props.getProducts()
        this.props.getStores()
        this.props.getTags()
    }

    handleOpenEntry = (event , id) => {
        event.stopPropagation();
        this.setState({
            openEntry: true,
            productId : id
        })
    }

    handleCloseEntry= () => {
        this.setState({
            openEntry: false
        })
    }


    handleInputChange = (event, value) => {
        this.setState({
            currency : value
        })
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name] : event.target.value,
        });
    }

    handleStore = (value) => {
        this.setState({
            resultStores : this.state.resultStores.concat(value.target.textContent)
        })
    }

    handleConcatStore = (event)=>{
        this.setState({
            [event.target.name] : event.target.value.trim(),
        });

        if(event.key === 'Enter'){
            event.preventDefault();
            this.state.resultStores.push(this.state.store.trim())
        }
    }

    handleTag =(value)=>{
        this.setState({
            resultTags : this.state.resultTags.concat(value.target.textContent)
        })
    }

    handleConcatTag = (event)=>{
        this.setState({
            [event.target.name] : event.target.value.trim(),
        });

        if(event.key === 'Enter'){
            event.preventDefault();
            this.state.resultTags.push(this.state.tag.trim())
        }
    }

    handleGetImage = (event) =>{
        if(event.target.files[0]){
            this.setState({
                beforeImage : event.target.files[0]
            })
        }
    }

    handleUpload = () => {
        if(this.state.beforeImage !== ''){
            const uploadTask =storage.ref(`products/${this.state.beforeImage.name}`).put(this.state.beforeImage)
            uploadTask.on("state_changed" , snapshot => {} , error => {console.log(error)} , () => {
                storage.ref('products').child(this.state.beforeImage.name).getDownloadURL().then((url) => {
                    this.setState({
                        imageEntry : url,
                    })
                }).then(()=>{
                    Toast.fire({
                        icon: 'success',
                        title: 'Upload Product Image Successfully'
                    })
                })
            })
        }else{
            Toast.fire({
                icon: 'error',
                title: 'Please select your image first'
            })
        }
    }

    handleSearch = (event) => {
        event.preventDefault();
        this.setState({
            [event.target.name] : event.target.value,
        });

        axios.get(`/products/search/${this.state.title}`).then((res)=>{
            this.setState({
                results : res.data
            })
        }).catch((err) =>{
            console.log(err)
        })
    };


    handleKeyUp = ()=>{
        var pictSection = document.querySelector('#pictSection');
        pictSection.style.display = 'none'
    }


    handleSubmit =(event) => {
        event.preventDefault();

        axios.post('/subProduct/create' , this.state).then((res)=>{
            const {store , resultStores , tag , resultTags , productId}  = this.state

            if(resultStores.length < 1){
                const newStore = {
                    productId     : productId,
                    subProductId  : res.data.id,
                    title         : store,
                    address       : '',
                    phone         : '',
                };
                axios.post('/store/create' , newStore).then(()=>{}).catch((error) =>{console.log(error)})
            }else{
                for (const item of resultStores) {
                    if(item  !== ''){
                        const newStores = {
                            productId     : productId,
                            subProductId  : res.data.id,
                            title         : item,
                            address       : '',
                            phone         : '',
                        };
                        axios.post('/store/create' , newStores).then(()=>{}).catch((error) =>{console.log(error)})
                    }
                }
            }

            if(resultTags.length < 1){
                const newTag = {
                    productId    : productId,
                    subProductId : res.data.id ,
                    title        : tag,
                };
                axios.post('/tag/create' , newTag).then(()=>{
                    this.handleCloseEntry()

                    Toast.fire({
                        icon: 'success',
                        title: 'Data successfully registered'
                    })
                }).catch((error) =>{console.log(error)})
            }else{
                for (const item of resultTags) {
                    if(item  !== ''){
                        const newTags = {
                            productId     : productId,
                            subProductId  : res.data.id,
                            title         : item,
                        };
                        axios.post('/tag/create' , newTags).then(()=>{
                            this.handleCloseEntry()

                            Toast.fire({
                                icon: 'success',
                                title: 'Data successfully registered'
                            })
                        }).catch((error) =>{console.log(error)})
                    }
                }
            }

        }).then(()=>{
            this.setState({
                brand         : '',
                unitsEntry    : '',
                price         : '',
                amount        : '',
                currency      : '',
                store         : '',
                tag           : '',
                beforeImage   : '',
                imageEntry    : '',
                productId     : '',
            })
        }).catch((err) =>{
            Toast.fire({
                icon: 'error',
                title: 'Please fill in the fields carefully'
            })
            this.setState({
                errors : err.response.data
            })
        })
    }


/* ========================== Render Component ===============================*/
    render(){
        const { classes, subUnits , products , stores , tags} = this.props;
        const {errors , results, imageEntry , beforeImage ,store, productId , tag}   = this.state;


/* ----------- Countries Config -----------*/

        function countryToFlag(isoCode) {
            return typeof String.fromCodePoint !== 'undefined'
                ? isoCode
                    .toUpperCase()
                    .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
                : isoCode;
        }

/* -------- Modal Tab Section ----------*/

        const DialogTitle = withStyles(styles)((props) => {
            const { children, classes, onClose, ...other } = props;

            return (
                <MuiDialogTitle disableTypography className={classes.dialog} {...other}>
                    <Typography variant="h6">{children}</Typography>
                    {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
            );
        });

 /* -------- Modal Tab Section ---------------*/

        function a11yProps(index) {
            return {
                id: `simple-tab-${index}`,
                'aria-controls': `simple-tabpanel-${index}`,
            };
        }

        const handleCreateProduct = ()=>{
            const newProduct = {
                title         : this.state.title,
                categoryId    : '',
                subCategoryId : '',
                units         : '',
                subUnits      : '',
                location      : '',
                image         : '',
                status        : 'accept'
            }

            if(this.state.title !== ''){
                axios.post('/product/create' , newProduct).then((res)=>{
                    window.location.href = `/createProduct/categories/${res.data.id}`
                }).catch((err) =>{
                    Toast.fire({
                        icon: 'error',
                        title: 'Please login first and then enter the product'
                    })
                    console.log(err)
                })
            }else{
                Toast.fire({
                    icon: 'warning',
                    title: 'Product name cannot be empty'
                })
            }
        }


        const handleCancelProduct = ()=>{
            window.location.href = `/search`
        }

        function notFoundProduct(){
            return (
                <div>
                    <Grid item md={12}>
                        <img src={notFound} className={classes.notFoundImage} />
                    </Grid>
                    <Grid  item md={12}>
                        <Typography className={classes.notFoundTitle}>Do you want to add it as a New Product ?</Typography>
                    </Grid>
                    <Grid  item md={12}>
                        <Link onClick={handleCreateProduct} style={{textDecoration: 'none'}}><Typography className={classes.notFoundButtonLeft}>Yes</Typography></Link>
                        <Link onClick={handleCancelProduct} style={{textDecoration: 'none'}}><Typography className={classes.notFoundButtonRight}>No</Typography></Link>
                    </Grid>
                </div>
            )
        }


        return(
            <Grid container className={classes.root}>
                <Grid item md={12}>
                    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                        <Link color="inherit" href='/'>
                            Home
                        </Link>
                        <Link color="inherit" href='/search'>
                            Enter Data
                        </Link>
                        <Typography color="textPrimary" className={classes.selectedBreadcrumb}>
                            Search Or Create Product
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item md={12}>
                    <Typography className={classes.mainTitle}>Search Product</Typography>
                </Grid>
                <Grid item md={12}>
                    <Paper component="form" className={classes.search}>
                        <IconButton className={classes.iconButton} aria-label="menu">
                            <SearchIcon />
                        </IconButton>
                        <InputBase  name="title" id="title" value={(this.state.title)} onChange={this.handleSearch} onKeyUp={this.handleKeyUp} className={classes.input} placeholder="Search Or Create Products" inputProps={{ 'aria-label': 'Search Products' }} />
                        <IconButton type="submit" className={classes.iconButton} aria-label="search">
                            <Button type="submit" style={{ "&:hover": { backgroundColor :'transparent'}}}><AddAPhotoIcon style={{color :'#0d85e5',}} /></Button>
                        </IconButton>
                    </Paper>
                </Grid>


                {results.length !== 0 ?
                    <Grid item md={12}>
                        <div className={classes.productSectiion}>
                            <Grid container>
                                {results.map(product =>{
                                    return (
                                        <Grid item md={3}>
                                            <Card className={classes.productItem} elevation={3} key={product.id} product={product}>
                                                <CardActionArea>
                                                    <CardMedia className={classes.media}  title="Products">
                                                        <img src={product.image}  width="100%" height="100%" />
                                                        <Button className={classes.addButton} onClick={(event) => {this.handleOpenEntry(event , product.id)}} key={product.id}> <PostAddOutlinedIcon className={classes.addIcon} /></Button>
                                                    </CardMedia>
                                                </CardActionArea>
                                                <CardActions className={classes.footerCard}>
                                                    <CardContent className={classes.cardText}>
                                                        <Grid item md={12}>
                                                            <Typography className={classes.cardMainText}>{product.price} {product.currency}</Typography>
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid item md={9}>
                                                                <Typography className={classes.cardSubText} component={Route} to={`product/${product.id}`}>{product.title}</Typography>
                                                            </Grid>
                                                            <Grid item md={3}>
                                                                <Typography component={Route} to={`product/${product.id}`}><RemoveRedEyeOutlinedIcon className={classes.eyeIcon} /></Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    )
                                })
                                }
                            </Grid>
                        </div>
                    </Grid> :
                    <Grid item md={12} style={{marginTop:20}}>
                        {notFoundProduct()}
                    </Grid>
                }


                <Grid item md={12}>
                    <div id="pictSection" className={classes.pictSection}>
                        <img src={searchProduct} className={classes.searchProduct} />
                        <Typography className={classes.subTitle}>Search the product to add your data</Typography>
                    </div>
                </Grid>

 {/* --------------------------- Modal Section --------------------------------*/}
                <Dialog open={this.state.openEntry} onClose={this.handleCloseEntry} fullWidth maxWidth="xs">
                    <DialogTitle id="customized-dialog-title" onClose={this.handleCloseEntry}></DialogTitle>

                    <form onSubmit={this.handleSubmit}>
                        <DialogContent>
                            <Grid container>
                                <div className={classes.modalBox}>
                                    <Grid item md={12}>
                                        <FormControl  variant="outlined">
                                            <InputLabel htmlFor="brand" className={classes.labelInput}>Brand Name</InputLabel>
                                            <OutlinedInput className={classes.brandInput} id="brand" name="brand"  type="text" value={this.state.brand}  onChange={this.handleChange} labelWidth={90} error={errors.brand ? true : false} />
                                            <FormHelperText error={errors.brand ? true : false} className={classes.error}>{errors.brand}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item md={12}>
                                        <FormControl variant="outlined">
                                            <InputLabel id="unitsId" className={classes.labelInput}>Units</InputLabel>
                                            <Select label="Units" labelId="unitsId" name="unitsEntry"  value={this.state.unitsEntry} className={classes.brandInput} onChange={this.handleChange} error={errors.unitsEntry ? true : false}>
                                                <MenuItem value=""> <em>None</em> </MenuItem>
                                                {subUnits.map(subUnit =>{
                                                    return(
                                                        products.map((product)=>{
                                                            if(subUnit.unitId === product.units && product.id === productId) {
                                                                return(
                                                                    <MenuItem key={subUnit.id} value={subUnit.title}>{subUnit.title}</MenuItem>
                                                                )
                                                            }
                                                        })
                                                    )
                                                })}
                                            </Select>
                                            <FormHelperText error={errors.unitsEntry ? true : false} className={classes.error}>{errors.unitsEntry}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item md={12}>
                                        <FormControl  variant="outlined">
                                            <InputLabel htmlFor="amount" className={classes.labelInput}>Amount</InputLabel>
                                            <OutlinedInput className={classes.brandInput} id="amount" name="amount"  type="number" value={this.state.amount}  onChange={this.handleChange} labelWidth={90} error={errors.amount ? true : false} />
                                            <FormHelperText error={errors.amount ? true : false} className={classes.error}>{errors.amount}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item md={12}>
                                        <Grid container>
                                            <Grid item sm={4}>
                                                <FormControl variant="outlined">
                                                    <InputLabel htmlFor="price" className={classes.labelInput}>Last Price</InputLabel>
                                                    <OutlinedInput id="price" name="price"  type="number" value={this.state.price} className={classes.price} onChange={this.handleChange} labelWidth={75} error={errors.price ? true : false} />
                                                    <FormHelperText error={errors.price ? true : false} className={classes.error}>{errors.price}</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            <Grid item sm={4}>
                                                <FormControl variant="outlined" className={classes.flagCell}>
                                                    <Autocomplete onInputChange={this.handleInputChange}  className={classes.option} id="currency" autoHighlight options={countries}  getOptionLabel={(option) => option.currency} renderOption={(option) => (
                                                        <React.Fragment>
                                                            <div>
                                                                <span className={classes.flag}>{countryToFlag(option.code)}</span>
                                                                {option.currency}
                                                            </div>
                                                        </React.Fragment>)}

                                                       renderInput={(params) => (
                                                           <div ref={params.InputProps.ref}>
                                                                <TextField {...params}  label="Currency" variant="outlined" name="currency" style={{height: 45}}  inputProps={{ ...params.inputProps, autoComplete: 'new-password'}}  error={errors.currency ? true : false} />
                                                           </div>
                                                      )}/>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={12}>
                                        <FormControl  variant="outlined">
                                            <Autocomplete multiple onChange={this.handleStore} className={classes.brandInput} id="tags-filled" freeSolo
                                                  options={stores.map((option) => option.status === 'accept' && option.productId == productId  ? option.title.toString() : '')}
                                                   renderTags={(value, getTagProps) =>
                                                       value.map((option, index) => (
                                                           <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                                           ))
                                                   }
                                                   renderInput={(params) => (
                                                       <TextField value={store} name="store"  onKeyDown={(event) => this.handleConcatStore(event)}  {...params} variant="filled" label="Stores (Optional)"  />
                                                   )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={12}>
                                        <FormControl variant="outlined">
                                            <Autocomplete multiple onChange={this.handleTag} className={classes.brandInput} id="tags-filled" freeSolo
                                                  options={tags.map((option) => option.status === 'accept' && option.productId == productId ? option.title.toString() : '')}

                                                  renderTags={(value, getTagProps) =>
                                                      value.map((option, index) => (
                                                          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                                          ))
                                                  }
                                                  renderInput={(params) => (
                                                      <TextField value={tag} name="tag"  onKeyDown={(event) => this.handleConcatTag(event)}  {...params} variant="filled" label="Tags(Optional)"  />
                                                  )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={12}>
                                        <Grid container>
                                            <Grid item sm={4}>
                                                <input accept="image/*" id="entry-button-file"  name="imageEntry"  type="file"  onChange={this.handleGetImage} style={{height:'43px' , display:'none'}} />
                                                {imageEntry == '' ?
                                                    <label htmlFor="entry-button-file"><Button variant="contained" className={classes.uploadText} component="span" startIcon={<CloudUploadIcon />}>{beforeImage == '' ? 'Upload Photo' : 'Upload the selected Photo'}</Button></label> :
                                                    <label htmlFor="entry-button-file"><Button variant="contained" className={classes.uploadText} color="secondary" component="span" startIcon={<CloudUploadIcon />}>Uploaded Photo</Button></label>
                                                }
                                            </Grid>
                                            <Grid item sm={4}>
                                                <Button className={classes.modalImage}>
                                                    <AddAPhotoOutlinedIcon className={classes.iconImage} />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </DialogContent>

                        <DialogActions className={classes.modelFooter}>
                            <Button type="submit" variant="contained" className={classes.createData}>
                                Save The Data
                            </Button>
                            <Button onClick={this.handleUpload}  variant="contained" className={classes.dissmiss}>
                                Upload Photo
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </Grid>
        )
    }
}


/* ------------------------------------------------ Database Connection --------------------------------------------*/
Search.propTypes ={
    classes        : PropTypes.object.isRequired,
    searchProducts :  PropTypes.func.isRequired,

    getSubUnits   : PropTypes.func.isRequired,
    clearErrors   : PropTypes.func.isRequired,
    getProducts   : PropTypes.func.isRequired,
    getStores       : PropTypes.func.isRequired,
    getTags        : PropTypes.func.isRequired,

    products      : PropTypes.array.isRequired,
    ui            : PropTypes.object.isRequired
}


const mapStateToProps = (state)=> ({
    stores        : state.data.stores,
    tags          : state.data.tags,
    products      : state.data.products,
    subUnits      : state.category.subUnits,
    ui            : state.ui,
    search        : state.data.search
})

const mapActionsToProps = {
    getSubUnits,
    getProducts,
    searchProducts ,
    clearErrors,
    getStores,
    getTags,
}


/* --------------------------------------------------- Styles Component ----------------------------------------------*/
const styles = (theme) => ({
    root: {
        flexGrow: 1,
        marginBottom: theme.spacing(7)
    },

    breadcrumb : {
        margin : '3% 0 0 6.4%',
        color : '#403232',
        fontWeight : '500',
        fontSize :'16px',
        fontFamily : 'Roboto'
    },

    selectedBreadcrumb : {
        fontWeight : '500',
        fontSize :'16px',
        fontFamily : 'Roboto',
        color : '#0D85E5',
    },


    mainTitle : {
        fontSize: 25,
        fontFamily : 'Roboto',
        fontWeight : '700',
        marginTop : '3%' ,
        marginLeft :'42.2%'
    },

    search: {
        background : '#FAFAFA',
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 560,
        height: "44px",
        marginLeft:'29%',
        marginTop: '2%',
        border:'1px solid #ABABAB'
    },

    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },

    iconButton: {
        padding: 4,
        "&:hover": {
            backgroundColor: "transparent"
        },
    },

    searchButton : {
        background : '#0d85e5',
        color : 'white',
        width: 140,
        height: "34px",
    },

    searchProduct:{
        marginLeft:'43%',
        marginTop: '3%'
    },

    subTitle :{
        marginLeft:'40%',
        marginTop: '1%',
        color:'#7E7E7E'
    },

    productSectiion : {
        marginTop : 70 ,
        marginLeft :80 ,
        marginRight :80
    },

    productItem: {
        marginRight :20,
        marginBottom : 20
    },

    media :{
        backgroundSize :'cover',
        height : 170,
        padding :'9px 9px 9px 9px',
        borderRadius :'8px',
        position: 'relative'
    },

    cardText : {
        padding :'2px 0 0 3px',
    },

    footerCard : {
        paddingBottom : 0
    },

    cardMainText:{
        color : '#0D85E5',
        paddingBottom: 8,
        fontSize : '14px'
    },

    cardSubText:{
        fontSize : '16px',
        color :'black',
        textDecoration : 'none'
    },

    eyeIcon :{
        padding :'0 5px 0 206px',
        float: 'right',
        color : '#403232'
    },

    addButton: {
        position : 'absolute',
        right :0
    },

    addIcon: {
        backgroundColor: 'rgba(0, 0, 0 , 0.3)',
        width : 25,
        height :25 ,
        padding : '4px',
        borderRadius: '50%',
        color:'white',
    },

    pictSection :{
        display: 'none'
    },

    closeButton :{
        float :'right'
    },

    modelFooter :{
        marginBottom: 17
    },

    error :{
        margin : '-6px 0px 6px 2px'
    },

    createData:{
        color : 'white',
        backgroundColor: '#0D85E5',
        textAlign :'center',
        margin: '0 12px 0 0',
        width :157,
        textTransform :'none',
        letterSpacing :'none',
    },

    dissmiss : {
        textTransform :'none',
        letterSpacing :'none',
        color : '#0D85E5',
        backgroundColor: 'rgba(13,133,229,0.05)',
        borderColor :'#0D85E5',
        width :157,
        margin: '0 44px 0 0'
    },

    labelInput:{
        top :0
    },

    modalBox:{
        margin : '0 35px 0 35px'
    },

    modalTitle :{
        margin : '0 0 13px 0',
        color : '#7E7E7E',
        fontWeight : 'bold'
    },

    dialog :{
        padding : '6px 7px 0 0'
    },

    brandInput:{
        width: 333,
        height: 45,
        backgroundColor: '#FAFAFA',
        margin : '0 0 13px 0',
    },

    price :{
        width: 200,
        height: 45,
        backgroundColor: '#FAFAFA',
        margin : '0 0 13px 0',
    },

    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
        height : '45px',
        width :'120px'
    },

    flagCell :{
        marginTop : '-0.4%',
        background : '#fcfcfc',
        marginLeft :'100px',
        height : 45
    },

    modalUpload :{
        width: 260,
        height: 45,
        backgroundColor: '#FAFAFA',
        margin : '0 0 13px 0',
    },

    modalImage:{
        width : 45 ,
        height: 45 ,
        border: '1px solid #0D85E5',
        borderRadius : '5px',
        backgroundColor: 'rgba(13,133,229,0.05)',
        margin:'0 0 0 158px'
    },

    iconImage:{
        color : '#0D85E5',
        fontWeight :'bold',
        fontSize : '25px'
    },




    dialogUpload : {
        margin: 0,
        padding: 14,
        background : '#F8F8F8'
    },

    closeButtonUpload: {
        position: 'absolute',
        right: 13,
        top: 8,
        color: 'gray',
    },


    uploadButton :{
        background : '#0D85E5',
        borderColor : '#0D85E5',
        color : 'white',
        fontWeight : '600',
        textTransform :'none',
        letterSpacing :'none',
    },

    uploadText :{
        width:261 ,
        height:43,
        textTransform :'none',
    },

    cancelButton :{
        background : 'white',
        borderColor : '#0D85E5',
        color : '#0D85E5',
        fontWeight : '600',
    },


    mainTab :{
        background : '#F8F8F8',
        color : '#393636',
        fontWeight : '600',
        fontSize : '14',
        width :'100%'

    },


    title :{
        width : '43.6ch'
    },


    uploadSection :{
        margin : '85px 0 10px 277px',
    },

    buttonUpload : {
        background : '#FCFCFC',
        fontWeight : '600',
        borderColor : '#7E7E7E',
        color : '#393636'

    },

    dragPhoto : {
        width: '31%',
        height: '100%',
        margin: '15px 0 0 303px'
    },

    notFoundImage:{
        width: '9%',
        margin: '18px 0 0 45%'
    },

    notFoundTitle:{
        color : '#393636',
        fontFamily :'Roboto',
        fontSize : '19px',
        margin: '51px 0 0 38%'
    },

    notFoundButtonLeft :{
        width :90 ,
        height :38 ,
        color : 'white',
        backgroundColor :'#0D85E5',
        margin : '51px 0 0 38%',
        padding : '9px 0 0 64px',
        borderRadius :5,
        fontFamily: 'Roboto',
        fontSize :'17px',
        cursor: 'pointer'
    },

    notFoundButtonRight :{
        width :90 ,
        height :38 ,
        color : '#0D85E5',
        border : '1px solid #0D85E5',
        backgroundColor :'rgba(13,133,229,5%)',
        margin : '-47px 0 0 51%',
        padding : '9px 0 0 64px',
        borderRadius :5,
        fontFamily: 'Roboto',
        fontSize :'17px',
        cursor: 'pointer'
    }

})



export default connect(mapStateToProps , mapActionsToProps)(withStyles(styles)(Search));