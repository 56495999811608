/* -------------------------------------------- Mouduls and Component -----------------------------------*/
/* ================== General Mouduls ======================*/
import React, { Component } from 'react';
import {Grid} from "@material-ui/core";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link as Router} from 'react-router-dom'
import {withStyles} from "@material-ui/core/styles";
import {getCategories, getSubCategories, getUnits} from "../../../../store/actions/categoryAction";
import {clearErrors, getProducts} from "../../../../store/actions/dataAction";

/* ================== Exclusive Mouduls ======================*/
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import axios from "axios";

/* ------------------------------------------------- Render Component ----------------------------------------------*/
class subCategories extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }

    componentDidMount(){
        this.props.getProducts()
        this.props.getCategories()
        this.props.getSubCategories()
    }

    updateSubCategory = (event , title) =>{
        event.stopPropagation();
        axios.put('/product/update/'+this.props.match.params.id ,{
            subCategoryId : title ,
        }).then((res)=>{
        }).catch((err) =>{
            console.log(err)
        })
    }



    /* ========================== Dedicated Methods ===============================*/
    render(){
        const { classes, ui: {loading} ,subCategories, products} = this.props;
        const {errors}   = this.state


        return(
            <Grid container className={classes.root}>
                <Grid item md={12}>
                    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                        <Link color="inherit" href='/'>
                            Home
                        </Link>
                        <Link color="inherit" href="/search">
                            Search Or Create
                        </Link>
                        <Typography color="textPrimary" className={classes.selectedBreadcrumb}>
                            {products.map((product)=>{
                                return(
                                    <div key={product.id}>
                                        {product.id === this.props.match.params.id ? product.categoryId : ''}
                                    </div>
                                )
                            })}
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item md={12}>
                    <Typography className={classes.carouselText}>Choose the subCategories :</Typography>
                </Grid>
                <Grid item md={12}>
                    <img />
                    <Typography></Typography>
                </Grid>

                <Grid item md={12}>
                    <div className={classes.mainCategory}>
                        {subCategories.map((subCategory) =>{
                            if (subCategory.status == 'accept' && window.location.pathname == `/createProduct/subCategories/${this.props.match.params.id}/${subCategory.categoryId}`){
                                return(
                                    <Paper elevation={2} className={classes.cardItem} component={Router} to={`/createProduct/units/${this.props.match.params.id}/${subCategory.title}`} onClick={(event) => {this.updateSubCategory(event , subCategory.title)}}>
                                        <img src={subCategory.image} className={classes.catImage}  />
                                        <Typography className={classes.catText}>{subCategory.title}</Typography>
                                    </Paper>
                                )
                            }
                        })}
                    </div>
                </Grid>

                <Grid item md={12}>
                    <Grid container>
                        <Grid item md={6}>
                            <Link component={Router} to={`/createProduct/categories/${this.props.match.params.id}`}><Typography className={classes.backText}>
                                <KeyboardBackspaceIcon style={{margin :'-7px 8px -7px 0px'}} />
                                Back
                            </Typography></Link>
                        </Grid>
                        <Grid item md={6}>
                            <Link href="/createSubCategory/"><Typography className={classes.findText}>Cannot find a Sub Category ?</Typography></Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}



/* ------------------------------------------------ Database Connection --------------------------------------------*/

subCategories.propTypes ={
    classes       : PropTypes.object.isRequired,

    getCategories : PropTypes.func.isRequired,
    getSubCategories : PropTypes.func.isRequired,
    clearErrors   : PropTypes.func.isRequired,
    getProducts   : PropTypes.func.isRequired,

    categories    : PropTypes.array.isRequired,
    subCategories : PropTypes.array.isRequired,
    products      : PropTypes.array.isRequired,

    ui            : PropTypes.object.isRequired
}




const mapStateToProps = (state)=> ({
    categories    : state.category.categories,
    subCategories : state.category.subCategories,
    products      : state.data.products,
    ui            : state.ui,
})



const mapActionsToProps = {
    getProducts,
    getCategories ,
    getSubCategories,
    clearErrors
}



/* --------------------------------------------------- Styles Component ----------------------------------------------*/
const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },

    breadcrumb : {
        margin : '3.5% 0 0 7.7%',
        color : '#403232',
        fontWeight : '500',
        fontSize :'16px',
        fontFamily : 'Roboto'
    },

    selectedBreadcrumb : {
        color : '#0D85E5',
        fontFamily : 'Roboto'
    },

    product :{
        margin : '2.5% 0 0 7.7%',
        fontFamily : 'Roboto'
    },

    productTitle :{
        margin : '-0.5% 0 0 35%',
        fontFamily :'Roboto'
    },

    mainTitle :{
        color : '#717171',
        fontWeight : '500',
        fontFamily :'Roboto'
    },



    carouselText :{
        margin : '3% 0 1.2% 7.6%',
        fontWeight : '800',
        fontSize : 20,
        color : '#403232'
    },

    mainCategory :{
        margin : '1% 0 6% 7.2%',
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(14),
            height: theme.spacing(14),
        },
    },

    cardItem :{
        position : 'relative',
        width: 259,
        height: 100,
        borderRadius : 10,
        marginRight : '2%',
        cursor: 'pointer',
    },

    catImage : {
        width: '100%',
        height: '100%',
        border:'none',
        borderRadius : 10,
        filter :'brightness(0.4)',
        backgroundColor: 'black',
        "&:hover": {
            filter :'brightness(0.8)',
            transition: 'all 0.1s ease-in',
        },
    },


    catText :{
        textAlign : 'center',
        position: 'absolute',
        top : 36 ,
        left :90 ,
        fontSize : 17,
        color : 'white',
        fontWeight : '700',
    },

    findText:{
        textAlign : 'right',
        fontFamily :'Roboto',
        margin : '3.2% 13.2% 0 0',
        color : '#0F2741',
        cursor: 'pointer',
        textDecoration :'none'
    },

    backText:{
        textAlign : 'left',
        fontFamily :'Roboto',
        margin : '3.2% 0 0 15.2%',
        color : '#0F2741',
        cursor: 'pointer',
        textDecoration :'none'
    }
});



export default connect(mapStateToProps , mapActionsToProps)(withStyles(styles)(subCategories));