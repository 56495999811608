/* -------------------------------------------- Mouduls and Component -----------------------------------*/
/* ================== General Mouduls ======================*/
import React, { Component } from 'react';
import {Grid} from "@material-ui/core";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link as Router} from 'react-router-dom'
import {withStyles} from "@material-ui/core/styles";
import {getCategories, getSubCategories, getUnits} from "../../../../store/actions/categoryAction";
import {clearErrors, createProduct, getProducts} from "../../../../store/actions/dataAction";
import axios from "axios";

/* ================== Exclusive Mouduls ======================*/
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";

/* ------------------------------------------------- Render Component ----------------------------------------------*/
class categories extends Component {
    constructor(props){
        super(props);
        this.state = {
            categoryId : ''
        }
    }

    componentDidMount(){
        this.props.getCategories()
        this.props.getProducts()
    }

    updateCategory = (event , title) =>{
        event.stopPropagation();
        axios.put('/product/update/'+this.props.match.params.id ,{
            categoryId : title ,
        }).then((res)=>{
        }).catch((err) =>{
            console.log(err)
        })
    }



/* ========================== Dedicated Methods ===============================*/
    render(){
        const { classes, ui: {loading} , categories , products} = this.props;
        const {errors}   = this.state
        return(
            <Grid container className={classes.root}>
                <Grid item md={12}>
                    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                        <Link color="inherit" href='/'>
                            Home
                        </Link>
                        <Link color="inherit" href="/search">
                            Search Or Create
                        </Link>
                        <Grid className={classes.selectedBreadcrumb}>
                            {products.map((product)=>{
                                return(
                                    <div key={product.id}>
                                        {product.id === this.props.match.params.id ? product.title : ''}
                                    </div>
                                )
                            })}
                        </Grid>
                    </Breadcrumbs>
                </Grid>
                <Grid item md={12}>
                    <Grid className={classes.carouselText}>Choose the categories : </Grid>
                </Grid>
                <Grid item md={12}>
                    <div className={classes.mainCategory}>
                        {categories.map((category)=>{
                            if(category.status == 'accept'){
                                return(
                                    <Paper elevation={2}  className={classes.cardItem} key={category.id} component={Router} to={`/createProduct/subCategories/${this.props.match.params.id}/${category.title}`} onClick={(event) => {this.updateCategory(event , category.title)}}>
                                        <img src={category.image} className={classes.catImage} />
                                        <Grid className={classes.catText}>{category.title}</Grid>
                                    </Paper>
                                )
                            }
                        })}
                    </div>
                </Grid>
                <Grid item md={12}>
                    <Link component={Router} to={`/createCategory/${this.props.match.params.id}`}><Grid className={classes.findText}>Cannot find a category ?</Grid></Link>
                </Grid>
            </Grid>
        )
    }
}



/* ------------------------------------------------ Database Connection --------------------------------------------*/

categories.propTypes ={
    classes       : PropTypes.object.isRequired,
    createProduct : PropTypes.func.isRequired,

    getCategories : PropTypes.func.isRequired,
    getSubCategories : PropTypes.func.isRequired,
    clearErrors   : PropTypes.func.isRequired,
    getProducts   : PropTypes.func.isRequired,

    categories    : PropTypes.array.isRequired,
    subCategories : PropTypes.array.isRequired,
    products      : PropTypes.array.isRequired,

    ui            : PropTypes.object.isRequired
}




const mapStateToProps = (state)=> ({
    categories    : state.category.categories,
    subCategories : state.category.subCategories,
    products      : state.data.products,
    ui            : state.ui,
})



const mapActionsToProps = {
    getCategories ,
    getProducts,
    getSubCategories,
    createProduct,
    clearErrors
}



/* --------------------------------------------------- Styles Component ----------------------------------------------*/
const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },

    breadcrumb : {
        margin : '3.5% 0 0 7.7%',
        color : '#403232',
        fontWeight : '500',
        fontSize :'16px',
        fontFamily : 'Roboto'
    },

    selectedBreadcrumb : {
        color : '#0D85E5',
        fontFamily : 'Roboto'
    },

    product :{
        margin : '2.5% 0 0 7.7%',
        fontFamily : 'Roboto'
    },

    productTitle :{
        margin : '-0.5% 0 0 35%',
        fontFamily :'Roboto'
    },

    mainTitle :{
        color : '#717171',
        fontWeight : '500',
        fontFamily :'Roboto'
    },



    carouselText :{
        margin : '3% 0 1.2% 7.6%',
        fontWeight : '800',
        fontSize : 20,
        color : '#403232'
    },

    mainCategory :{
        marginLeft : '7%',
        marginTop :'1%',
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(14),
            height: theme.spacing(14),
        },
    },

    cardItem :{
        width: 352,
        height: 174,
        marginRight : '2.7%',
        cursor: 'pointer',
        textDecoration: 'none',
        "&:hover": {
            backgroundColor:'#0D85E5',
            transition: 'all 0.1s ease-in',
            catText : {
                color:'white',
            }
        },
    },

    catImage : {
        textAlign : 'center',
        width: 60,
        height: 60,
        marginLeft : '40.2%',
        marginTop :'7%',
    },

    catText :{
        textAlign : 'center',
        marginTop :'8%',
        fontSize : 17,
        color : '#717171',
        fontWeight : '700',
        "&:hover": {
            color:'white',
        },
    },

    findText:{
        textAlign : 'right',
        fontFamily :'Roboto',
        margin : '3.2% 8.2% 0 0',
        color : '#0F2741',
        cursor: 'pointer',
        textDecoration :'none'
    }
});



export default connect(mapStateToProps , mapActionsToProps)(withStyles(styles)(categories));