/* -------------------------------------------- Mouduls and Component -----------------------------------*/
/* ================== General Mouduls ======================*/
import React, { Component } from 'react';
import {Grid} from "@material-ui/core";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import {createSubCategory, getCategories} from "../../../store/actions/categoryAction";
import {clearErrors} from "../../../store/actions/dataAction";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {storage} from "../../../config/config";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";





class createSubCategories extends Component {
    constructor(props){
        super(props);

        this.state = {
            title          : '' ,
            description    : '',
            categoryId     : '',
            image          : '' ,
            beforeImage    : '',
            errors         : {},
        }
    }


    componentDidMount(){
        this.props.getCategories()
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.ui.errors){
            this.setState({
                errors : nextProps.ui.errors
            })
        }

        if(!nextProps.ui.errors && !nextProps.ui.loading){
            this.setState({
                title          : '' ,
                description    : '',
                categoryId  : '',
                image          : '' ,
            })
        }
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name] : event.target.value,
        });
    }


    handleSubmit =(event) => {
        event.preventDefault();
        this.props.createSubCategory(this.state)
        this.props.clearErrors ()
    }


    handleGetImage = (event) =>{
        if(event.target.files[0]){
            this.setState({
                beforeImage : event.target.files[0]
            })
        }
    }


    handleUpload = () => {
        const uploadTask = storage.ref(`subCategories/${this.state.beforeImage.name}`).put(this.state.beforeImage)

        uploadTask.on("state_changed" , snapshot => {} , error => {console.log(error)} , () => {
            storage.ref('subCategories').child(this.state.beforeImage.name).getDownloadURL().then((url) => {
                this.setState({
                    image : url
                })
            })
        })
    }


    render(){
        const {classes , ui : {loading} , categories} = this.props
        const {errors}   = this.state

        return(
            <Grid container>
                <form noValidate onSubmit={this.handleSubmit} className={classes.formStyle}>
                    <Grid item sm={12}>
                        <FormControl variant="outlined" style={{marginBottom : 10}}>
                            <InputLabel htmlFor="title">Title</InputLabel>
                            <OutlinedInput className={classes.inputStyle} id="title" name="title"  type="text" value={this.state.title}  onChange={this.handleChange} labelWidth={35} error={errors.title ? true : false} />
                            <FormHelperText error={errors.title ? true : false}>{errors.title}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item sm={12}>
                        <FormControl variant="outlined" style={{marginBottom : 10}}>
                            <InputLabel htmlFor="description">Description</InputLabel>
                            <OutlinedInput className={classes.inputStyle} id="description" name="description"  type="text" value={this.state.description}  onChange={this.handleChange} labelWidth={85} error={errors.description ? true : false} />
                            <FormHelperText error={errors.description ? true : false}>{errors.description}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item sm={12}>
                        <FormControl variant="outlined" style={{marginBottom : 10}}>
                            <InputLabel id="categoryId">Category</InputLabel>
                            <Select className={classes.inputStyle} label="Category" labelId="categoryId" name="categoryId" value={this.state.categoryId}  onChange={this.handleChange} error={errors.categoryId ? true : false}>
                                <MenuItem value=""> <em>None</em> </MenuItem>
                                {categories.map(category =>{
                                    return (
                                        <MenuItem key={category.id} value={category.title}>{category.title}</MenuItem>
                                    )
                                })}
                            </Select>
                            <FormHelperText error={errors.categoryId ? true : false}>{errors.categoryId}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item sm={12}>
                        <FormControl variant="outlined" style={{marginBottom : 10}}>
                            <OutlinedInput accept="image/*" className={classes.inputStyle} id="image" name="image"  type="file"  onChange={this.handleGetImage}  labelWidth={0} error={errors.image ? true : false} />
                            <FormHelperText error={errors.image ? true : false}>{errors.image}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item sm={12}>
                        <Button type="submit" variant="contained" disabled={loading}>
                            Create subCategory
                            {loading && (
                                <CircularProgress size={31} color="secondary" className={classes.progress} />
                            )}
                        </Button>
                    </Grid>
                </form>
                <Grid item md={12}>
                    <Button className={classes.uploadImage} type="submit" variant="contained" onClick={this.handleUpload}>Upload Image</Button>
                </Grid>
            </Grid>
        )
    }
}




/* ------------------------------------------------ Database Connection --------------------------------------------*/
createSubCategories.propTypes ={
    classes       : PropTypes.object.isRequired,
    createSubCategory : PropTypes.func.isRequired,

    getCategories : PropTypes.func.isRequired,
    clearErrors   : PropTypes.func.isRequired,

    categories    : PropTypes.array.isRequired,
    ui            : PropTypes.object.isRequired
}




const mapStateToProps = (state)=> ({
    categories    : state.category.categories,
    ui            : state.ui,
})



const mapActionsToProps = {
    getCategories ,
    createSubCategory,
    clearErrors
}



/* --------------------------------------------------- Styles Component ----------------------------------------------*/
const styles = (theme) => ({
    formStyle :{
        margin : '80px 0 0 520px'
    },

    inputStyle :{
        width :330
    },

    uploadImage :{
        margin: '-62px 0 0 730px'
    }
});


export default connect(mapStateToProps , mapActionsToProps)(withStyles(styles)(createSubCategories))