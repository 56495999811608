/* -------------------------------------------- Mouduls and Component -----------------------------------*/
/* ================== General Mouduls ======================*/
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux'
import PropTypes from 'prop-types';
import {storage} from "../../../../config/config";
import {getCategories , getSubCategories , getSubUnits} from '../../../../store/actions/categoryAction'
import {getProducts, singleProduct, getStores , getTags} from '../../../../store/actions/dataAction'
import axios from "axios";
import {Link as Router} from "react-router-dom";

/* ================== Exclusive Mouduls ======================*/
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {countries} from '../../../../config/countries'
import image2 from '../../../../images/single/bread-1 (2).png'
import image3 from '../../../../images/single/bread-1 (3).png'
import image4 from '../../../../images/single/bread-1 (1).png'
// import {getProducts} from '../../store/actions/dataAction'
import PersonPinCircleOutlinedIcon from '@material-ui/icons/PersonPinCircleOutlined';
import { Button } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddAPhotoOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined';
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Swal from 'sweetalert2'
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
let Toast = Swal.mixin({
    toast: true,
    position: 'bottom-start',
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})


/* ------------------------------------------------- Render Component ----------------------------------------------*/
class addData extends Component {
    constructor(props){
        super(props);

        this.state = {
            brand         : '',
            unitsEntry    : '',
            amount          : '',
            price         : '',
            currency      : '',
            store         : '',
            resultStores  : [],
            tag           : '',
            resultTags    : [],
            beforeImage   : '',
            imageEntry    : '',
            productId       : this.props.match.params.id,
            errors          : {},
        }
    }


 /* ========================== Dedicated Methods ===============================*/
    componentDidMount(){
        this.props.getSubUnits()
        this.props.getProducts()
        this.props.getStores()
        this.props.getTags()
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name] : event.target.value,
        });
    }

    handleGetImage = (event) =>{
        if(event.target.files[0]){
            this.setState({
                beforeImage : event.target.files[0]
            })
        }
    }

    handleInputChange = (event, value) => {
        this.setState({
            currency : value
        })
    }

    handleStore = (value) => {
        this.setState({
            resultStores : this.state.resultStores.concat(value.target.textContent)
        })
    }


    handleTag =(value)=>{
        this.setState({
            resultTags : this.state.resultTags.concat(value.target.textContent)
        })
    }


    handleConcatStore = (event)=>{
        this.setState({
            [event.target.name] : event.target.value.trim(),
        });

        if(event.key === 'Enter'){
            event.preventDefault();
            this.state.resultStores.push(this.state.store.trim())
        }
    }

    handleConcatTag = (event)=>{
        this.setState({
            [event.target.name] : event.target.value.trim(),
        });

        if(event.key === 'Enter'){
            event.preventDefault();
            this.state.resultTags.push(this.state.tag.trim())
        }
    }

    handleUpload = () => {
        if(this.state.beforeImage !== ''){
            const uploadTask =storage.ref(`products/${this.state.beforeImage.name}`).put(this.state.beforeImage)
            uploadTask.on("state_changed" , snapshot => {} , error => {console.log(error)} , () => {
                storage.ref('products').child(this.state.beforeImage.name).getDownloadURL().then((url) => {
                    this.setState({
                        imageEntry : url,
                    })
                }).then(()=>{
                    Toast.fire({
                        icon: 'success',
                        title: 'Upload Product Image Successfully'
                    })
                })
            })
        }else{
            Toast.fire({
                icon: 'error',
                title: 'Please select your image first'
            })
        }
    }

    handleSubmit =(event) => {
        event.preventDefault();
        axios.post('/subProduct/create' , this.state).then((res)=>{
            const {store , resultStores , tag , resultTags}  = this.state
            const success =`/createProduct/success/${this.props.match.params.id}`

            if(resultStores.length !== 0){
                if(resultStores.length < 1){
                    const newStore = {
                        productId     : this.props.match.params.id,
                        subProductId  : res.data.id,
                        title         : store,
                        address       : '',
                        phone         : '',
                    };
                    axios.post('/store/create' , newStore).then(()=>{window.location.href = success}).catch((error) =>{console.log(error)})
                }else{
                    for (const item of resultStores) {
                        if(item  !== ''){
                            const newStores = {
                                productId     : this.props.match.params.id,
                                subProductId  : res.data.id,
                                title         : item,
                                address       : '',
                                phone         : '',
                            };
                            axios.post('/store/create' , newStores).then(()=>{window.location.href = success}).catch((error) =>{console.log(error)})
                        }
                    }
                }
            }else{
                window.location.href = success;
            }

            if(resultTags !== 0){
                if(resultTags.length < 1){
                    const newTag = {
                        productId    : this.props.match.params.id,
                        subProductId : res.data.id ,
                        title        : tag,
                    };
                    axios.post('/tag/create' , newTag).then(()=>{window.location.href = success}).catch((error) =>{console.log(error)})
                }else{
                    for (const item of resultTags) {
                        if(item  !== ''){
                            const newTags = {
                                productId     : this.props.match.params.id,
                                subProductId  : res.data.id,
                                title         : item,
                            };
                            axios.post('/tag/create' , newTags).then(()=>{window.location.href = success}).catch((error) =>{console.log(error)})
                        }
                    }
                }
            }else {
                window.location.href = success;
            }


            Toast.fire({
                icon: 'success',
                title: 'Add entry data Successfully'
            })

        }).catch((err) =>{
            this.setState({
                errors : err.response.data
            })
            Toast.fire({
                icon: 'error',
                title: 'There is a Problem . please check it'
            })
        })
    }


    render() {
        const { classes , ui: {loading} , subUnits , products , stores , tags} = this.props;
        const {errors , imageEntry , beforeImage , store , tag}   = this.state

        function countryToFlag(isoCode) {
            return typeof String.fromCodePoint !== 'undefined'
                ? isoCode
                    .toUpperCase()
                    .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
                : isoCode;
        }

        /* -------------------------- Table Config ------------------------------------------*/


        return (
            <Grid container className={classes.root}>
                <Grid item md={12}>
                    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                        <Link color="inherit" href='/'>
                            Home
                        </Link>
                        <Link color="inherit" href="/search">
                            Search Or Create
                        </Link>
                        <Grid color="textPrimary">
                            {products.map((product)=>{
                                return(
                                    <div key={product.id}>
                                        {product.id === this.props.match.params.id ? <Link component={Router} to={`/createProduct/subCategories/${this.props.match.params.id}/${product.categoryId }`} style={{color : '#403232' , fontFamily :'Roboto'}}>{product.categoryId}</Link> : ''}
                                    </div>
                                )
                            })}
                        </Grid>
                        <Grid color="textPrimary" className={classes.selectedBreadcrumb}>
                            {products.map((product)=>{
                                return(
                                    <div key={product.id}>
                                        {product.id === this.props.match.params.id ? <Link component={Router} to={`/createProduct/units/${this.props.match.params.id}/${product.subCategoryId }`} style={{color : '#403232' , fontFamily :'Roboto'}}>{product.subCategoryId}</Link> : ''}
                                    </div>
                                )
                            })}
                        </Grid>
                        <Grid color="textPrimary" className={classes.selectedBreadcrumb}>
                            {products.map((product)=>{
                                return(
                                    <div key={product.id}>
                                        {product.id === this.props.match.params.id ? product.units : ''}
                                    </div>
                                )
                            })}
                        </Grid>
                    </Breadcrumbs>
                </Grid>
                <Grid item md={12}>
                    <Grid color="textPrimary" className={classes.productTitle}>
                        {products.map((product)=>{
                            return(
                                <div key={product.id}>
                                    {product.id === this.props.match.params.id ? product.title : ''}
                                </div>
                            )
                        })}
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <div className={classes.update}>
                        <Grid container>
                            <Grid item md={1}>
                                <Grid color="inherit" >Average : </Grid>
                            </Grid>
                            <Grid item md={2}>
                                <Grid className={classes.mainTitle}><PersonPinCircleOutlinedIcon className={classes.location} /> In Canada</Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item md={12}>
                    <div className={classes.imageSection}>
                        <Grid container>
                            <Grid item md={4}>
                                <Paper className={classes.imageOne}  elevation={3}>
                                    {products.map((product)=>{
                                        if(product.id === this.props.match.params.id){
                                            return(
                                                <img key={product.id}  className={classes.backDown} src={product.image} />
                                            )
                                        }
                                    })}
                                </Paper>
                            </Grid>
                            <Grid item md={4}>
                                <Paper className={classes.imageTwo}  elevation={3}>
                                    <img  className={classes.backDown} src={image2} />
                                </Paper>
                            </Grid>
                            <Grid item md={4}>
                                <Paper className={classes.imageThere}  elevation={3}>
                                    <img  className={classes.backDown} src={image3} />
                                </Paper>
                                <Paper className={classes.imageFour}  elevation={3}>
                                    <img  className={classes.backDown} src={image4} />
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item md={12}>
                    <div className={classes.accordion}>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" style={{backgroundColor:'#FCFCFC'}}>
                                <Grid className={classes.accordionTitle}>+ Add My Data</Grid>
                            </AccordionSummary>
                            <AccordionDetails style={{backgroundColor:'#FCFCFC'}}>
                                <Grid container>
                                    <form onSubmit={this.handleSubmit}>
                                        <Grid item md={12}>
                                            <FormControl  variant="outlined" className={classes.inputStyle}>
                                                <InputLabel htmlFor="brand" style={{marginTop :'-3px' , fontFamily :'Roboto',}}>Brand</InputLabel>
                                                <OutlinedInput  name="brand"  type="text" value={this.state.brand}  onChange={this.handleChange} labelWidth={45} error={errors.brand ? true : false} style={{height:'43px'}} />
                                                <FormHelperText error={errors.brand ? true : false} className={classes.error}>{errors.brand}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={12}>
                                            <FormControl variant="outlined" className={classes.inputStyle}>
                                                <InputLabel id="unitsId" className={classes.labelInput}>Units</InputLabel>
                                                <Select label="Units" labelId="unitsId" name="unitsEntry"  value={this.state.unitsEntry}  onChange={this.handleChange} error={errors.unitsEntry ? true : false} style={{height:'43px'}} >
                                                    <MenuItem value=""> <em>None</em> </MenuItem>
                                                    {subUnits.map(subUnit =>{
                                                        return(
                                                            products.map((product)=>{
                                                                if(subUnit.unitId === product.units && product.id === this.props.match.params.id) {
                                                                    return(
                                                                        <MenuItem key={subUnit.id} value={subUnit.title}>{subUnit.title}</MenuItem>
                                                                    )
                                                                }
                                                            })
                                                        )
                                                    })}
                                                </Select>
                                                <FormHelperText error={errors.unitsEntry ? true : false} className={classes.error}>{errors.unitsEntry}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={12}>
                                            <FormControl  variant="outlined" className={classes.inputStyle}>
                                                <InputLabel htmlFor="amount" className={classes.labelInput}> Enter The Amount</InputLabel>
                                                <OutlinedInput name="amount"  type="number" value={this.state.amount}  onChange={this.handleChange} labelWidth={118} error={errors.amount ? true : false } style={{height:'43px'}} />
                                                <FormHelperText error={errors.amount ? true : false} className={classes.error}>{errors.amount}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={12}>
                                            <Grid container>
                                                <Grid item sm={8}>
                                                    <FormControl className={classes.latPrice} variant="outlined">
                                                        <InputLabel htmlFor="latPrice" className={classes.labelInput}>last Price</InputLabel>
                                                        <OutlinedInput id="latPrice" name="price"  type="number" value={this.state.price}  onChange={this.handleChange} labelWidth={70} error={errors.price ? true : false} style={{height:'43px'}} />
                                                        <FormHelperText error={errors.price ? true : false} className={classes.error}>{errors.price}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item sm={4}>
                                                    <FormControl variant="outlined" className={classes.currency}>
                                                        <Autocomplete   onInputChange={this.handleInputChange} style={{height:'43px'}}  id="currency" autoHighlight options={countries} className={classes.option}  getOptionLabel={(option) => option.currency} renderOption={(option) => (
                                                            <React.Fragment>
                                                                <div>
                                                                    <span className={classes.flag}>{countryToFlag(option.code)}</span>
                                                                    {option.currency}
                                                                </div>
                                                            </React.Fragment>)}
                                                                      renderInput={(params) => (
                                                                          <TextField {...params}  label="Currency" variant="outlined" name="currency" inputProps={{ ...params.inputProps, autoComplete: 'new-password'}}  error={errors.currency ? true : false}   />
                                                                      )}/>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item md={12}>
                                            <FormControl  variant="outlined" className={classes.inputStyle}>
                                                <Autocomplete multiple onChange={this.handleStore} className={classes.store} id="tags-filled" freeSolo
                                                      options={stores.map((option) => option.status === 'accept' ? option.title.toString() : '')}

                                                      renderTags={(value, getTagProps) =>
                                                          value.map((option, index) => (
                                                              <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                                          ))
                                                      }

                                                      renderInput={(params) => (
                                                          <TextField value={store} name="store"  onKeyDown={(event) => this.handleConcatStore(event)}  {...params} variant="filled" label="Stores" style={{fontFamily:'Roboto'}}  />
                                                      )}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={12}>
                                            <FormControl  variant="outlined" className={classes.inputStyle}>
                                                <Autocomplete multiple onChange={this.handleTag} className={classes.store} id="tags-filled" freeSolo
                                                      options={tags.map((option) => option.status === 'accept' ? option.title.toString() : '')}

                                                       renderTags={(value, getTagProps) =>
                                                           value.map((option, index) => (
                                                               <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                                           ))
                                                       }

                                                       renderInput={(params) => (
                                                           <TextField value={tag} name="tag"  onKeyDown={(event) => this.handleConcatTag(event)}  {...params} variant="filled" label="Tags"  />
                                                      )}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={12}>
                                            <Grid container>
                                                <Grid item md={11}>
                                                    <input accept="image/*" id="contained-button-file"  name="imageEntry"  type="file"  onChange={this.handleGetImage} style={{height:'43px' , display:'none'}} />
                                                    {imageEntry == '' ?
                                                        <label htmlFor="contained-button-file"><Button variant="contained" style={{width:1056 , height:43}} component="span" startIcon={<CloudUploadIcon />}>{beforeImage == '' ? 'Upload Photo' : 'Please upload the selected Photo'}</Button></label> :
                                                        <label htmlFor="contained-button-file"><Button variant="contained" style={{width:1056 , height:43}} color="secondary" component="span" startIcon={<CloudUploadIcon />}>Uploaded Photo</Button></label>
                                                    }
                                                </Grid>
                                                <Grid item md={1}>
                                                    <Button className={classes.addPhoto}>
                                                        <AddAPhotoOutlinedIcon />
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item md={12}>
                                            <Grid container>
                                                <Grid item md={3}></Grid>
                                                <Grid item md={3}></Grid>
                                                <Grid item md={3}>
                                                    <Button onClick={this.handleUpload} className={classes.imageProduct}>
                                                        Upload Photo
                                                        {loading && (
                                                            <CircularProgress size={31} color="secondary" className={classes.progress} />
                                                        )}
                                                    </Button>
                                                </Grid>
                                                <Grid item md={3}>
                                                    <Button type="submit" className={classes.createProduct}>Create Product</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </Grid>
                <Grid item md={12}>
                    <Grid container>
                        <Grid item md={6}>
                            <Link component={Router} to={`/createProduct/upload/${this.props.match.params.id}`}><Grid className={classes.backText}>
                                <KeyboardBackspaceIcon style={{margin :'-7px 8px -7px 0px'}} />
                                Back
                            </Grid></Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}





addData.propTypes ={
    getSubUnits   : PropTypes.func.isRequired,
    classes       : PropTypes.object.isRequired,
    ui            : PropTypes.object.isRequired,
    getProducts   : PropTypes.func.isRequired,
    products      : PropTypes.array.isRequired,
    getStores       : PropTypes.func.isRequired,
    getTags        : PropTypes.func.isRequired,
}



const mapStateToProps = (state)=> ({
    categories : state.category.categories,
    subCategories : state.category.subCategories,
    subUnits      : state.category.subUnits,
    ui          : state.ui,
    products      : state.data.products,
    product     : state.data.product,
    stores        : state.data.stores,
    tags          : state.data.tags,
})



const mapActionsToProps = {
    getStores,
    getTags,
    getProducts,
    singleProduct,
    getCategories ,
    getSubCategories,
    getSubUnits,
}


/* --------------------------------------------------- Styles Component ----------------------------------------------*/
const styles = (theme) => ({
    root: {
        flexGrow: 1,
        marginBottom : theme.spacing(7)
    },

    input: {
        display: 'none',
    },

    breadcrumb : {
        margin : '3.5% 0 0 6.4%',
        color : '#403232',
        fontWeight : '500',
        fontSize :'17px',
        fontFamily : 'Roboto'
    },

    selectedBreadcrumb : {
        color : '#0D85E5',
        fontFamily : 'Roboto'
    },

    productTitle :{
        margin : '2% 0 0 6.4%',
        color : '#403232',
        fontWeight : '800',
        fontSize :'21px',
        fontFamily : 'Roboto'
    },

    update :{
        fontFamily : 'Roboto',
        color : '#403232',
        margin : '2.2% 0 0 6.4%'
    },

    mainTitle :{
        color : '#717171',
        fontWeight : '500',
    },


    location: {
        color : '#717171',
        fontSize :'18px'
    },

    imageSection : {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin:'3% 0 0 6.1%' ,
        },
    },


    imageOne : {
        width : 450 ,
        height : 246
    },


    imageTwo :{
        width : 450 ,
        height : 246,
        margin:'0 0 0 12%' ,
    },

    imageThere :{
        width : 232 ,
        height : 115,
        margin:'0 0 0 24%' ,
    },

    imageFour :{
        width : 230 ,
        height : 115,
        margin:'4% 0 0 24%' ,
    },

    backDown :{
        filter: 'brightness(60%)',
        width : '102%' ,
        height :'102%',
    },

    accordion :{
        margin : '2% 6.3% 0 6.2%',
    },

    accordionTitle:{
        color : '#403232',
        fontWeight : '600',
        fontSize :'16px',
        fontFamily : 'Roboto'
    },

    inputStyle :{
        width : '102.3%',
        backgroundColor: '#FAFAFA',
        borderColor :'#E6E6E6',
        margin :'0 0 1.8% 0'
    },

    labelInput:{
        marginTop : '-6px',
        fontFamily :'Roboto'
    },


    latPrice: {
        width : '105%',
        backgroundColor: '#FAFAFA',
        borderColor :'#E6E6E6',
        margin :'0 0 3% 0'
    },

    currency : {
        width : '91.5%',
        backgroundColor: '#FAFAFA',
        borderColor :'#E6E6E6',
        margin :'0 0 0 15%'
    },

    uploadPhoto :{
        width : '374%',
        backgroundColor: '#FAFAFA',
        borderColor :'#E6E6E6',
        margin :'0 0 4% 0'
    },

    addPhoto:{
        margin :'0px 0px 0% 58%',
        height:45,
        border :'1px solid #C6C6C6'
    },

    createProduct:{
        width :164,
        margin :'27% 0px 3% 52.8%',
        textTransform :'none',
        backgroundColor:'#0D85E5',
        color:'white',
        fontFamily :'Roboto',
        "&:hover": {
            color : 'black'
        },
    },

    imageProduct:{
        width :164,
        margin :'27% 0px 3% 84%',
        textTransform :'none',
        backgroundColor:'#0D85E5',
        color:'white',
        fontFamily :'Roboto',
        position : 'relative',
        "&:hover": {
            color : 'black'
        },
    },

    backText:{
        textAlign : 'left',
        fontFamily :'Roboto',
        margin : '8.2% 0 0 12%',
        color : '#0F2741',
        cursor: 'pointer',
        textDecoration :'none'
    },

    error :{
        margin : '2px 0px 0 2px'
    },

    progress : {
        position : 'absolute'
    }

});

export default connect(mapStateToProps , mapActionsToProps)(withStyles(styles)(addData))
