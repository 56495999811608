/* -------------------------------------------- Mouduls and Component -----------------------------------*/
/* ================== General Mouduls ======================*/
import React, { Component } from 'react';
import {Grid} from "@material-ui/core";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link as Router} from 'react-router-dom'
import {withStyles} from "@material-ui/core/styles";
import {clearErrors,getProducts} from "../../../../store/actions/dataAction";

/* ================== Exclusive Mouduls ======================*/
import Link from "@material-ui/core/Link";
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import ScreenShareOutlinedIcon from '@material-ui/icons/ScreenShareOutlined';
import succes from './../../../../images/succes.png'
import Paper from "@material-ui/core/Paper";


/* ------------------------------------------------- Render Component ----------------------------------------------*/
class created extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }


    /* ========================== Dedicated Methods ===============================*/
    componentDidMount(){
       this.props.getProducts()
    }


    render(){
        const { classes ,products} = this.props;
        const {errors}   = this.state

        return(
            <Grid container className={classes.root}>
                <Grid item md={12}>
                    <img src={succes} className={classes.uploadIcon}/>
                    <Typography  className={classes.carouselText}>
                        Share this Product and get others data
                    </Typography>
                </Grid>
                <Grid item md={12}>
                    <Link  component={Router} to={`/product/${this.props.match.params.id}`}><ScreenShareOutlinedIcon className={classes.share} /></Link>
                    <Button className={classes.newProduct} component={Router} to={`/search`}>Enter Another Data</Button>
                </Grid>
            </Grid>
        )
    }
}



/* ------------------------------------------------ Database Connection --------------------------------------------*/

created.propTypes ={
    classes       : PropTypes.object.isRequired,
    ui            : PropTypes.object.isRequired,
    getProducts   : PropTypes.func.isRequired,
}




const mapStateToProps = (state)=> ({
    products      : state.data.products,
    ui            : state.ui,
})



const mapActionsToProps = {
    getProducts,
    clearErrors
}



/* --------------------------------------------------- Styles Component ----------------------------------------------*/
const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },

    uploadIcon:{
        margin : '5% 0 0 41.1%',
        width: '17%'
    },

    mainTitle :{
        color : '#717171',
        fontWeight : '500',
        fontFamily :'Roboto'
    },

    carouselText :{
        textAlign :'center',
        margin : '2.6% 0 1.2% 0',
        fontWeight : '700',
        fontSize : 17,
        color : '#403232'
    },

    share:{
        margin : '1.4% 0px 1.2% 48.5%',
        fontSize: 37,
        color :'#0D85E5',
        "&:hover": {
            color : 'orangered'
        },
    },

    newProduct:{
        width :200 ,
        margin :'1.8% 0px 3% 42.7%',
        textTransform :'none',
        backgroundColor:'#0D85E5',
        color:'white',
        fontFamily :'Roboto',
        "&:hover": {
            color : 'black'
        },
    },

});



export default connect(mapStateToProps , mapActionsToProps)(withStyles(styles)(created));