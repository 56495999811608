import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { createMuiTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import AvatarProfile from '../../images/avatar.png'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import image2 from '../../images/single/bread-1 (2).png'



const styles = {
    divider:{
        position :'relative',
        width: '81%',
        height: 2,
        marginLeft : '9%',
        marginTop :'10%',
        marginBottom :'8%',
        borderTop: '1.4px solid #CCCCCC',
    },


    avatar :{
        position :'absolute',
        height : 100,
        width: 100,
        top: -53,
        left: 502,
        borderRadius : '50%',
    },

    avatarBackground :{
        borderRadius : '50%',
    },

    dividerText :{
        fontWeight : '500',
        fontSize : 20,
        marginTop : 60 ,
        textAlign: 'center',
        color : '#121010'
    },

    myData: {
        flexGrow: 1,
        margin : '7% 0 0 0',
    },

    myDataTab :{
        backgroundColor:'#FCFCFC',
        color : 'white',
        borderRadius : '5px',
        borderColor:'none',
        paddingLeft: '61px'
    },

    headerTab :{
        width :500,
        height : 15,
        backgroundColor: '#0D85E5',
        margin :'6px',
        borderRadius : '5px',
    },

    indicator: {
        backgroundColor: 'transparent',
    },

    myDataContent : {
        width : 900
    },
    
    carousel :{
        width:244,
        height: 210,
    },

    media: {
        height: 125,
        backgroundPosition: 'center'
    },
      

    cardMainText : {
        fontWeight : '700',
        fontSize : 15,
        color : '#393636'
    },

    cardSubText : {
        fontWeight : '500',
        fontSize : 13,
        color : '#0D85E5',
        marginTop :5
    },

    backDown :{
        filter: 'brightness(60%)',
        width : '102%' ,
        height :'102%',
    },
}



class userProfile extends Component {

    constructor(){
        super();

        this.state = {
          value : 0
        }
    }


     handleChange = (event, newValue) => {
        this.setState({
            value : newValue
        })
    };


    render() {
        const {classes , authenticated , user : {credentials : {handle , createdAt , imageUrl , phone , biography} , loading}} = this.props

        function TabPanel(props) {
            const { children, value, index, ...other } = props;
            return (
              <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
                {value === index && (
                  <Box p={3}>
                    <Typography>{children}</Typography>
                  </Box>
                )}
              </div>
            );
        }

        TabPanel.propTypes = {
            children: PropTypes.node,
            index: PropTypes.any.isRequired,
            value: PropTypes.any.isRequired,
        };

        
        function a11yProps(index) {
            return {
                id: `simple-tab-${index}`,'aria-controls': `simple-tabpanel-${index}`,
            };
        };

        const theme = createMuiTheme({
            overrides: {
              MuiTab: {
                root: {
                  "&.MuiTab-root": {
                    border: 0,
                    borderBottom: "2px solid",
                    "&:hover": {
                      border: 0,
                      borderBottom: "2px solid",
                    },
                  },
                  "&.Mui-selected": {
                    backgroundColor: "none",
                    borderBottom: "2px solid #373985",
                    borderColor: "#373985",
                  }
                }
              }
            }
          });

  
        let profileMarkup = !loading ? (authenticated ? (
            <Grid container>
                <Grid item md={12}>
                    <div className={classes.divider}>
                        <div className={classes.avatarBackground}>
                            <Avatar alt="Avatar" src={AvatarProfile} className={classes.avatar}></Avatar>
                        </div>
                        <Typography className={classes.dividerText}>{handle}</Typography>
                    </div>
                </Grid>
                <Grid item md={3}></Grid>
                <Grid item md={6}>
                    <div className={classes.myData}>
                        <AppBar position="static" className={classes.myDataTab}>
                            <Tabs value={this.state.value} onChange={this.handleChange} classes={{indicator: classes.indicator}} aria-label="simple tabs example">
                                <Tab label="Data Entries"  className={classes.headerTab} {...a11yProps(0)} />
                                <Tab label="My lists" className={classes.headerTab} {...a11yProps(1)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel className={classes.myDataContent} value={this.state.value} index={0}>
                            <Card className={classes.carousel} elevation={2}>
                                <CardActionArea>
                                    <CardMedia  className={classes.media}  title="Products">
                                        <img src={image2} className={classes.backDown} />
                                    </CardMedia>
                                </CardActionArea>
                                 <CardActions>
                                    <CardContent className={classes.cardText}>
                                        <Typography className={classes.cardMainText}>Product</Typography>
                                        <Typography className={classes.cardSubText}>23521 CAD</Typography>
                                        </CardContent>
                                </CardActions>
                            </Card>
                        </TabPanel>
                        <TabPanel value={this.state.value} index={1}>
                            Item Two
                        </TabPanel>
                    </div>
                </Grid>
                <Grid item md={3}></Grid>
            </Grid>
        ) : (
        
            <Typography className={classes.dividerText}>No Data . Please Login To Site</Typography>
          
        )) : (<p className={classes.dividerText}> Loading ... </p>)

        return profileMarkup
    }
}





userProfile.propTypes ={
    classes       : PropTypes.object.isRequired,
    user          : PropTypes.object.isRequired,
    authenticated : PropTypes.bool.isRequired,
}




const mapStateToProps = (state)=> ({
    user          : state.user,
    authenticated : state.user.authenticated
})




export default connect(mapStateToProps , null)(withStyles(styles)(userProfile))
