import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LinkText from '@material-ui/core/Link';
import {Link} from 'react-router-dom';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';

import {connect} from 'react-redux'
import PropTypes from 'prop-types';
import {signUpUser} from '../../../store/actions/userAction'
import {logoutUser} from '../../../store/actions/userAction'
import Background from '../../../images/register_image.png'


const styles = {
    form :{
        textAlign : 'center'
    },

    image :{
        width : '96%',
        height : '110.5%'
    },

    mainTitle : {
        marginTop :45,
        fontWeight : '700'
    },

    subTitle : {
        marginTop :6,
        fontSize : 13,
        color : '#7E7E7E'
    },

    forget : {
        marginTop :13,
        fontSize : 13,
        color : '#7E7E7E',
        marginLeft : 195,
        cursor: 'pointer',
        textDecoration: 'none'

    },

    signUp : {
        marginTop :40,
        fontSize : 13,
        color : '#7E7E7E',
        marginLeft : 145,
    },

    email: {
        width: '45ch',
        marginTop : 32
    },


    password: {
        width: '45ch',
        marginTop : 17
       
    },

    size : {
        height : '48px',
        background : '#fcfcfc'
    },

    error : {
        color : 'red',
        textAlign : 'left',
        marginTop : 3,
        fontSize : 14,
    },

    login : {
        marginTop :17,
        width : 230,
        marginLeft : 85,
        background : '#0d85e5',
        color : 'white',
        fontWeight : 'bold',
        position : 'relative'
    },

    form_style :{
        marginLeft : 50
    },

    progress : {
        position : 'absolute'
    },

    hidden:{
        display : 'none'
    }
}



class signUp extends Component {
    constructor(){
        super()
        this.state = {
            email        : '' ,
            password     : '' ,
            confirmPassword : '' ,
            handle       : '',
            showPassword : false ,
            errors       : {}
        }
    }



    componentWillReceiveProps(nextProps){
        if(nextProps.ui.errors){
            this.setState({
                errors : nextProps.ui.errors
            })
        }
    }


    handleSubmit =(event) => {
        event.preventDefault();

        const newUserData = {
            email    : this.state.email ,
            password : this.state.password,
            confirmPassword : this.state.confirmPassword,
            handle   : this.state.handle
        }

        this.props.signUpUser(newUserData , this.props.history)
    }


    handleChange = (event) => {
        this.setState({
            [event.target.id] : event.target.value
        })
    }


    handleClickShowPassword =() =>{
        this.setState({
           showPassword : !this.state.showPassword
        })
    }


    handleMouseDownPassword = (event) => {
        event.preventDefault();
    }


    render() {
        const {classes , ui : {loading}} = this.props
        const {errors}   = this.state
        return (
            <Grid container>
                <Grid item sm={6} > 
                    <img src={Background} className={classes.image}/>
                </Grid>
                <Grid item sm={6}>
                    <form noValidate onSubmit={this.handleSubmit} className={classes.form_style}>
                        <Grid>
                            <Grid item sm={12}>
                                <Typography variant="h6" className={classes.mainTitle}>Data By People</Typography>
                                <Typography  className={classes.subTitle}>Sign up in order to entering data and making your shopping list</Typography>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid item sm={12}>
                                <FormControl className={classes.email} variant="outlined">
                                    <InputLabel htmlFor="email" className={classes.label_email}>Email</InputLabel>
                                    <OutlinedInput id="email"  type="email" value={this.state.email} className={classes.size} onChange={this.handleChange} labelWidth={40} error={errors.email ? true : false} />
                                    <FormHelperText error={errors.email ? true : false}>{errors.email}</FormHelperText>
                                </FormControl>
                                <OutlinedInput id="handle"  type="hidden" className={classes.hidden} value={this.state.handle = (this.state.email).substring(0, this.state.email.lastIndexOf("@"))}  onChange={this.handleChange} />
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid item sm={12}>
                                <FormControl className={classes.password} variant="outlined">
                                    <InputLabel htmlFor="password">Password</InputLabel>
                                    <OutlinedInput id="password" type={this.state.showPassword ? 'text' : 'password'} value={this.state.password} className={classes.size} onChange={this.handleChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton aria-label="toggle password visibility" onClick={this.handleClickShowPassword} onMouseDown={this.handleMouseDownPassword} edge="end">
                                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                                }
                                        labelWidth={70} error={errors.password ? true : false}
                                    />
                                    <FormHelperText error={errors.password ? true : false}>{errors.password}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid item sm={12}>
                                <FormControl className={classes.password} variant="outlined">
                                    <InputLabel htmlFor="confirmPassword">Confirm Password</InputLabel>
                                    <OutlinedInput id="confirmPassword" type={this.state.showPassword ? 'text' : 'password'} value={this.state.confirmPassword} className={classes.size} onChange={this.handleChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton aria-label="toggle password visibility" onClick={this.handleClickShowPassword} onMouseDown={this.handleMouseDownPassword} edge="end">
                                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                                }
                                        labelWidth={130} error={errors.confirmPassword ? true : false}
                                    />
                                    <FormHelperText error={errors.confirmPassword ? true : false}>{errors.confirmPassword}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {errors.general && (
                            <Grid>
                                <Grid item sm={12}>
                                    <Typography variant="h6" className={classes.error}>{errors.general}</Typography>
                                </Grid>
                            </Grid>
                        )}


                        <Grid>
                            <Grid item sm={12}>
                                <Button type="submit" className={classes.login} variant="contained" disabled={loading}>
                                    Sign Up
                                    {loading && (
                                        <CircularProgress size={31} color="secondary" className={classes.progress} />
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>

                      
                    <Grid>
                        <Grid item sm={12}>
                            <LinkText><Typography className={classes.forget}>Forget Password ?</Typography></LinkText>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Grid item sm={12}>
                            <Typography className={classes.signUp}>Already Have an account ? <LinkText component={Link} to='/login'>Login here</LinkText></Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}



signUp.propTypes ={
    classes   : PropTypes.object.isRequired,
    logoutUse : PropTypes.func.isRequired,
    user      : PropTypes.object.isRequired,
    ui        : PropTypes.object.isRequired
}



const mapStateToProps = (state)=> ({
    user : state.user,
    ui   : state.ui
})



export default connect(mapStateToProps , {signUpUser})(withStyles(styles)(signUp))

