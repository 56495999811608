import {LOADING_DATA,SET_SUB_PRODUCTS,SET_TAGS, SET_PRODUCTS,SET_STORES, CREATE_PRODUCT, SET_PRODUCT , SEARCH_PRODUCT , CREATE_SUB_PRODUCT} from '../types'

const initiaState = {
    products : [] ,

    stores :[],
    tags   : [],

    subProducts : [] ,
    product : {} ,

    search : {},
    loading : false
} 



export default function(state = initiaState , action){
    switch(action.type){
        case LOADING_DATA :
            return {
                ...state,
                loading : true
            };


        case SET_PRODUCTS :
             return {
                ...state,
                products : action.payload ,
                loading : false
            };


        case SET_STORES :
            return {
                ...state,
                stores : action.payload ,
                loading : false
            };


        case SET_TAGS :
            return {
                ...state,
                tags : action.payload ,
                loading : false
            };


        case SET_SUB_PRODUCTS :
            return {
                ...state,
                subProducts : action.payload ,
                loading : false
            };


        case SET_PRODUCT:
             return {
               ...state,
               product : action.payload
          };


        case SEARCH_PRODUCT :
            return {
                ...state,
                search : action.payload
            };

        case CREATE_PRODUCT :
              return {
               ...state,
               products : [
                   action.payload ,
                   ...state.products
                ],
            };

        case CREATE_SUB_PRODUCT :
            return {
                ...state,
                subProduct : [
                    action.payload ,
                    ...state.subProducts
                ],
            };

        default :
            return state;
    }
}