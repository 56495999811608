/* ----------------------------------------- Mouduls and Component -------------------------------*/
import React, { Component } from 'react';





class compare extends Component {
    render(){
        return(
            <h1 style={{textAlign:'center' , margin :'180px 0 180px 0'}}>Compare Page Coming Soon ... </h1>
        )
    }
}




export default compare;