/* ----------------------------------------- Mouduls and Component Config -------------------------------*/
import {CREATE_PRODUCT ,SET_SUB_PRODUCTS ,SET_STORES, SET_ERRORS , CLEAR_ERRORS , LOADING_UI , LOADING_DATA ,SET_TAGS, SET_PRODUCTS , SET_PRODUCT , STOP_LOADING_UI , SEARCH_PRODUCT , CREATE_SUB_PRODUCT} from '../types'
import axios from 'axios'
import Swal from 'sweetalert2'

let Toast = Swal.mixin({
    toast: true,
    position: 'bottom-start',
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})




/* ----------------------------------------- Get and Create Productions -------------------------------*/
export const getProducts = () => (dispatch) =>{

    dispatch({type : LOADING_DATA})

    axios.get('/products').then((res)=>{

        dispatch({
            type : SET_PRODUCTS,
            payload : res.data
        })

    }).catch((err) =>{
        dispatch({
            type : SET_PRODUCTS,
            payload : []
        })
    })
}




export const singleProduct = (productId) => (dispatch) => {
    dispatch({type : LOADING_UI})

    axios.get(`/product/${productId}`).then((res)=>{

        dispatch({
            type : SET_PRODUCT,
            payload : res.data
        })

        dispatch({
            type : STOP_LOADING_UI,
        })

    }).catch((err) =>{
        console.log(err)
    })
}





export const createProduct = (newProduct) => (dispatch) =>{

    dispatch({type : LOADING_UI})

    axios.post('/product/create' , newProduct).then((res)=>{

        dispatch({
            type : CREATE_PRODUCT,
            payload : res.data
        })

        Toast.fire({
            icon: 'success',
            title: 'Product Create Successfully'
        })

        dispatch({
            type : CLEAR_ERRORS,
        })

    }).catch((err) =>{
        
        dispatch({
            type : SET_ERRORS,
            payload : err.response.data
        })

        Toast.fire({
            icon: 'info',
            title: 'Please fill in the fields completely'
        })
    })
}


export const getSubProducts = () => (dispatch) =>{

    dispatch({type : LOADING_DATA})

    axios.get('/subProducts').then((res)=>{

        dispatch({
            type : SET_SUB_PRODUCTS,
            payload : res.data
        })

    }).catch((err) =>{
        dispatch({
            type : SET_SUB_PRODUCTS,
            payload : []
        })
    })
}



export const createSubProduct = (newSubProduct) => (dispatch) =>{

    dispatch({type : LOADING_UI})

    axios.post('/subProduct/create' , newSubProduct).then((res)=>{

        dispatch({
            type : CREATE_SUB_PRODUCT,
            payload : res.data
        })

        Toast.fire({
            icon: 'success',
            title: 'Entry Product Create Successfully'
        })

        dispatch({
            type : CLEAR_ERRORS,
        })

    }).catch((err) =>{

        dispatch({
            type : SET_ERRORS,
            payload : err.response.data
        })

        Toast.fire({
            icon: 'info',
            title: 'Please fill in the fields completely'
        })
    })
}




export const searchProducts = (query) => (dispatch) => {
    dispatch({type : LOADING_UI})

    axios.get(`/products/search/${query}`).then((res)=>{

        dispatch({
            type : SEARCH_PRODUCT,
            payload : res.data
        })

        dispatch({
            type : STOP_LOADING_UI,
        })

    }).catch((err) =>{
        console.log(err)
    })
}



export const clearErrors = () => (dispatch) => {
    dispatch({
        type : CLEAR_ERRORS
    })
}


/* ----------------------------------------- Get and Create Stores -------------------------------*/
export const getStores = () => (dispatch) =>{

    dispatch({type : LOADING_DATA})

    axios.get('/stores').then((res)=>{

        dispatch({
            type : SET_STORES,
            payload : res.data
        })

    }).catch((err) =>{
        dispatch({
            type : SET_STORES,
            payload : []
        })
    })
}

/* ----------------------------------------- Get and Create Tags -------------------------------*/
export const getTags = () => (dispatch) =>{

    dispatch({type : LOADING_DATA})

    axios.get('/tags').then((res)=>{

        dispatch({
            type : SET_TAGS,
            payload : res.data
        })

    }).catch((err) =>{
        dispatch({
            type : SET_TAGS,
            payload : []
        })
    })
}