/* -------------------------------------------- Mouduls and Component -----------------------------------*/
/* ================== General Mouduls ======================*/
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

/* ================== Exclusive Mouduls ======================*/
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import {getProducts} from '../../store/actions/dataAction'
import rightPatch from '../../images/right.png'
import leftPatch from '../../images/left.png'
import homeImage from  '../../images/home_image.png'
import appleDownload from '../../images/apple.png'
import androidDownload from '../../images/android.png'
import CategoryOne from '../../images/cat1.png'
import CategoryTwo from '../../images/cat2.png'
import CategoryThere from '../../images/cat3.png'
import CategoryFour from '../../images/cat4.png'
import CategoryFive from '../../images/cat5.png'
import CategorySix from '../../images/cat6.png'
import Previous from '../../images/previous.png'
import Next from '../../images/next.png'
import axios from 'axios';




/* ------------------------------------------------- Render Component ----------------------------------------------*/
class home extends Component {
    constructor(props){
        super(props);

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);

        this.state = {
          
        }
    }


 /* ========================== Dedicated Methods ===============================*/
    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    componentDidMount(){
        axios.get('/categories').then((res)=>{
            console.log(res.data)
        })
        this.props.getProducts(this.props.productId)
    }


/* ========================== Render Component ===============================*/
    render() {
        const {classes , products , ui: {loading}} = this.props

        const settings = {
            infinite: true,
            speed: 900,
            slidesToShow: 4,
            slidesToScroll: 3,
            centerMode: true,
            swipeToSlide: true,
          };

        return (
            <Grid container>
                <Grid item md={12}>
                    <div className={classes.mainSection}>
                        <div className={classes.subSection}>
                            <Grid container>
                                <Grid item md={6}>
                                    <Grid  className={classes.mainTitle}>Data By People</Grid>
                                    <Grid  className={classes.subTitle}>Compare and prepare your shopping list</Grid>
                                    <Grid variant="contained" className={classes.available}>Our App is Available</Grid>
                                    <Grid variant="contained" className={classes.download}>Download the Data by People application</Grid>
                                    <div>
                                        <img src={appleDownload} className={classes.appleDownload} />
                                        <img src={androidDownload} className={classes.androidDownload} />
                                    </div>
                                    <img src={leftPatch} className={classes.leftPatch} />
                                </Grid>
                                <Grid item md={6}>
                                    <img src={rightPatch} className={classes.rightPatch} />
                                    <img src={homeImage} className={classes.homeImage} />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
                <Grid item md={12}>
                    <Grid className={classes.carouselText}>Categories</Grid>
                </Grid>
                <Grid item md={12}>
                    <div className={classes.mainCategory}>
                        <Paper elevation={2} className={classes.cardItem} style={{backgroundColor:'#0D85E5'}}>
                            <img src={CategoryOne} className={classes.catImage} style={{marginLeft :'35%'}} />
                            <Grid className={classes.catText} style={{color : 'white'}}>Food</Grid>
                        </Paper>
                        <Paper elevation={2} className={classes.cardItem}>
                            <img src={CategoryTwo} className={classes.catImage}/>
                            <Grid className={classes.catText}>Clothing</Grid>
                        </Paper>
                        <Paper elevation={2} className={classes.cardItem}>
                            <img src={CategoryThere} className={classes.catImage}/>
                            <Grid className={classes.catText}>Healthcare</Grid>
                        </Paper>
                    </div>
                    <div className={classes.mainCategory}>
                        <Paper elevation={2} className={classes.cardItem}>
                            <img src={CategoryFour} className={classes.catImage} style={{marginLeft :'35%'}} />
                            <Grid className={classes.catText}>Appliances</Grid>
                        </Paper>
                        <Paper elevation={2} className={classes.cardItem}>
                            <img src={CategoryFive} className={classes.catImage}/>
                            <Grid className={classes.catText}>Trans portation</Grid>
                        </Paper>
                        <Paper elevation={2} className={classes.cardItem}>
                            <img src={CategorySix} className={classes.catImage}/>
                            <Grid className={classes.catText}>Beauty</Grid>
                        </Paper>
                    </div>
                    <div className={classes.mainCategory}>
                        <Paper elevation={2} className={classes.cardItem}>
                            <img src={CategoryFour} className={classes.catImage} style={{marginLeft :'35%'}} />
                            <Grid className={classes.catText}>Education</Grid>
                        </Paper>
                        <Paper elevation={2} className={classes.cardItem}>
                            <img src={CategoryFive} className={classes.catImage}/>
                            <Grid className={classes.catText}>Pet Care</Grid>
                        </Paper>
                        <Paper elevation={2} className={classes.cardItem}>
                            <img src={CategorySix} className={classes.catImage}/>
                            <Grid className={classes.catText}>Entertainment</Grid>
                        </Paper>
                    </div>
                </Grid>
                <Grid item md={12}>
                    <div className={classes.divider}>
                        <Grid className={classes.dividerText}>What`s most popular today in vancouver</Grid>
                    </div>
                </Grid>
                <Grid item md={12}>
                    <Grid className={classes.carouselText}>Food  
                        <div className={classes.carouselButton}>
                            <Button style={{ backgroundColor: 'transparent' }} onClick={this.previous}><img src={Previous} /></Button>
                            <Button style={{ backgroundColor: 'transparent' }} onClick={this.next}><img src={Next} /></Button>
                        </div>
                    </Grid>
                </Grid>
                
                <Grid item md={12}>
                    <Slider {...settings} ref={c => (this.slider = c)} className={classes.carouselFirst} >
                        {products.map((product) => {
                            return (
                                <div>
                                    <Card className={classes.carousel} elevation={2} key={product.id} product={product}>
                                        <CardActionArea>
                                            <CardMedia component={Link} to={`product/${product.id}`} className={classes.media}  title="Products">
                                                <img src={product.image} width="100%" height="100%" />
                                            </CardMedia>
                                        </CardActionArea>
                                        <CardActions>
                                            <CardContent className={classes.cardText}>
                                                <Grid className={classes.cardMainText}>{product.title}</Grid>
                                                <Grid className={classes.cardSubText}>{product.price} {product.currency}</Grid>
                                            </CardContent>
                                        </CardActions>
                                    </Card>
                                </div>
                            )
                        })}
                    </Slider>
                </Grid>

                <Grid item md={12}>
                    <Grid className={classes.carouselSecendText}>Application
                        <div className={classes.carouselButton}>
                            <Button style={{ backgroundColor: 'transparent' }} onClick={this.previous}><img src={Previous} /></Button>
                            <Button style={{ backgroundColor: 'transparent' }} onClick={this.next}><img src={Next} /></Button>
                        </div>
                    </Grid>
                </Grid>

                <Grid item md={12}>
                    <Slider {...settings} ref={c => (this.slider = c)} className={classes.carouselFirst} >
                        {products.map((product) => {
                            return (
                                <div>
                                    <Card className={classes.carousel} elevation={2} key={product.id} product={product}>
                                        <CardActionArea>
                                            <CardMedia component={Link} to={`product/${product.id}`} className={classes.media}  title="Products">
                                                <img src={product.image} width="100%" height="100%" />
                                            </CardMedia>
                                        </CardActionArea>
                                        <CardActions>
                                            <CardContent className={classes.cardText}>
                                                <Grid className={classes.cardMainText}>{product.title}</Grid>
                                                <Grid className={classes.cardSubText}>{product.price} {product.currency}</Grid>
                                            </CardContent>
                                        </CardActions>
                                    </Card>
                                </div>
                            )
                        })}
                    </Slider>
                </Grid>
            </Grid>
        )
    }
}

/* ------------------------------------------------ Database Connection --------------------------------------------*/
home.propTypes ={
    classes       : PropTypes.object.isRequired,
    getProducts   : PropTypes.func.isRequired,
    products      : PropTypes.array.isRequired,
    ui            : PropTypes.object.isRequired
}

const mapStateToProps = (state)=> ({
    products : state.data.products,
    ui       : state.ui
})


const mapActionsToProps = {
    getProducts
}


/* --------------------------------------------------- Styles Component ----------------------------------------------*/
const styles = (theme) => ({
    root: {
        flexGrow: 1,
        marginBottom : theme.spacing(7)
    },

    mainSection : {
        width :'100%',
        height : 440,
        background : 'white'
    },

    subSection : {
        width :'85.3%',
        height :330,
        background : 'rgba(13,133, 229, 0.08)',
        marginLeft : '7%',
        marginTop: '2%',
        paddingTop :30,
        borderRadius :9,
        position : 'relative'
    },


    rightPatch :{
        marginRight : 0,
        marginTop : -30 ,
        float : 'right',
        height :390,
    },


    homeImage :{
        top: 6,
        right: 111,
        width: 330,
        height: 327,
        position: 'absolute',
    },


    leftPatch :{
        position : 'absolute',
        left : 0,
        top : 230,
    },

    mainTitle : {
        marginTop : 17,
        marginLeft : 45,
        fontWeight : 'bold',
        fontFamily :'Roboto',
        fontSize :37
    },

    subTitle : {
        marginTop :17,
        marginLeft : 45,
        fontSize : 20,
        color : 'rgba(0, 0, 0, 0.644)',
        fontWeight : 'bold',
        fontFamily :'Roboto',
    },

    download : {
        fontSize : 14,
        color : 'rgba(0, 0, 0, 0.644)',
        marginLeft : 45,
        fontFamily :'Roboto',
        position : 'absolute',
        top : 210,
    },


    card: {
        maxWidth: 500,
        marginLeft  : theme.spacing(9),
        marginBottom   : theme.spacing(5),
    },

    product: {
        height:'auto',
        width: 650,
        marginRight  : theme.spacing(4),
        paddingTop : theme.spacing(3),
        paddingBottom : theme.spacing(3),
    },

    notification: {
        height: 400,
        width: 350,
    },

    link :{
        textDecoration: 'none'
    },

    available :{
        fontFamily :'Roboto',
        fontWeight : 800,
        fontSize : 18,
        color : 'rgba(0, 0, 0, 0.644)',
        marginLeft : 45,
        position : 'absolute',
        top : 173,
    },


    appleDownload :{
        marginLeft : 45,
        position : 'absolute',
        top : 250,
        width :'11.5%',
        height :42,
        zIndex : '2'
    },

    androidDownload : {
        marginLeft : 185,
        position : 'absolute',
        top : 250,
        width :'11.5%',
        height :42,
        zIndex : '2'
    },




    mainCategory :{
        marginLeft : '7%',
        marginTop :'1%',
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(14),
            height: theme.spacing(14),
        },
    },

    cardItem :{
        width: 352,
        height: 174,
        marginRight : '2.7%',
        cursor: 'pointer'
    },

    catImage : {
        width: 60,
        height: 60,
        marginLeft : '42%',
        marginTop :'7%',
    },

    catText :{
        textAlign : 'center',
        fontFamily : 'Roboto',
        marginTop :'8%',
        fontSize : 18,
        color : '#717171',
        fontWeight : '700'
    },

    divider:{
        position :'relative',
        width: '81%',
        height: 2,
        marginLeft : '9%',
        marginTop :'8%',
        marginBottom :'8%',
        borderTop: '2px solid rgba(0, 0, 0, 0.644)',
    },

    dividerText :{
        fontFamily : 'Roboto',
        fontWeight : '800',
        fontSize : 21,
        position :'absolute',
        top : -19,
        left : 368,
        background : 'white'
    },

    carouselFirst:{
        margin : '0 5.7% 3% 7.5%',
    },

    carousel :{
        width:254,
        height: 275,
    },

    carouselText :{
        fontFamily :'Roboto',
        margin : '0 0 2% 7.5%',
        fontWeight : '900',
        fontSize : 23,
        color : '#403232'
    },


    carouselSecendText :{
        margin : '1.4% 0 2% 7.5%',
        fontWeight : '800',
        fontSize : 18,
        color : '#403232'
    },

    media: {
        height: 195,
        backgroundPosition: 'center'
    },


    cardMainText : {
        fontWeight : '700',
        fontSize : 15,
        color : '#393636'
    },

    cardSubText : {
        fontWeight : '500',
        fontSize : 13,
        color : '#393632',
        marginTop :5
    },

    carouselButton: {
        margin : '0 4.8% 2% 0',
        float : 'right'
    },
});


export default connect(mapStateToProps , mapActionsToProps)(withStyles(styles, { withTheme: true })(home))
