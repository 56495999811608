import {SET_USER , SET_UNAUTHENTICATED , SET_AUTHENTICATED ,LOADING_USER} from '../types'


const initiaState = {
    authenticated : false,
    loading : false ,
    credentials : {} ,
    likes : [] ,
    notifications : []
} 


export default function(state = initiaState , action){
    switch(action.type){
        case SET_AUTHENTICATED :
            return {
                ...state,
                authenticated : true,
            };

        case SET_UNAUTHENTICATED :
            return initiaState;

        case SET_USER :
            return {
                authenticated : true,
                loading : false ,
                ...action.payload,
            };

         case LOADING_USER :
             return {
                ...state,
                loading : true
            };


        default :
            return state;
    }
}