/* -------------------------------------------- Mouduls and Component -----------------------------------*/
/* ================== General Mouduls ======================*/
import React, { Component , Fragment} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux'
import PropTypes from 'prop-types';

/* ================== Exclusive Mouduls ======================*/
import Grid from '@material-ui/core/Grid';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TelegramIcon from '@material-ui/icons/Telegram';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Logo from '../../../images/logoFooter.png';
import {logoutUser} from '../../../store/actions/userAction'
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography'


/* ------------------------------------------------- Render Component ----------------------------------------------*/
class footer extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }


    /* ========================== Dedicated Methods ===============================*/
    handleLogout =() => {
        this.props.logoutUser()
    }



    /* ========================== Render Component ===============================*/
    render() {
        const {classes ,authenticated , user : {credentials : {handle , createdAt , imageUrl , phone , biography} , loading}} = this.props

        return (
            <Grid container>
                <Grid item md={12} className={classes.mainFooter}>
                    <Grid container>
                        <Grid item md={4}>
                            <div className={classes.sectionOne}>
                                <Avatar component={Link} to='/' alt="Data By Pepople Logo" src={Logo} className={classes.logo}></Avatar>
                                <Typography className={classes.sectionTitle}>Compare and prepare your <br /> shopping list</Typography>
                                <div className={classes.social}>
                                   <Grid container>
                                       <Grid item md={2}><InstagramIcon /></Grid>
                                       <Grid item md={2}><TwitterIcon /></Grid>
                                       <Grid item md={2}><WhatsAppIcon /></Grid>
                                       <Grid item md={2}><TelegramIcon /></Grid>
                                       <Grid item md={2}></Grid>
                                   </Grid>
                                </div>
                            </div>
                        </Grid>
                        <Grid item md={4}>
                            <div className={classes.sectionTwo}>
                                <Button className={classes.sectionLink} component={Link} to='/compare'>Compare</Button><br />
                                <Button className={classes.sectionLink} component={Link} to='/favourites'>Favourites</Button><br />
                                <Button className={classes.sectionLink} component={Link} to='/myData'>My Data</Button>
                            </div>
                        </Grid>
                        <Grid item md={4}>
                            <div className={classes.sectionThere}>
                                <Button className={classes.sectionLink}>Contact us</Button><br />
                                <Button className={classes.sectionLink}>About us </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} className={classes.subFooter}>
                    <Typography className={classes.subTitle}>Copy write by farsi studio 2020</Typography>
                </Grid>
            </Grid>
        )
    }
}


/* ------------------------------------------------ Database Connection --------------------------------------------*/
footer.propTypes ={
    classes    : PropTypes.object.isRequired,
    logoutUser : PropTypes.func.isRequired,
    user       : PropTypes.object.isRequired,
    authenticated : PropTypes.bool.isRequired,
}

const mapStateToProps = (state)=> ({
    user : state.user,
    authenticated : state.user.authenticated
})

const mapActionsToProps = {
    logoutUser
}


/* --------------------------------------------------- Styles Component ----------------------------------------------*/
const styles = (theme) => ({
    mainFooter:{
        width : '100%',
        height : '285px',
        backgroundColor : '#0D85E5',
        marginTop : 50,
        boxShadow: '0px 1px 2px 2px #0D85E5'
    },

    sectionOne :{
        margin:'52px 0 20px 69px'
    },

    logo : {
        marginLeft :'5.5%',
        marginRight :'2.3%',
        width: "80px",
        height: "48px",
    },

    sectionTitle:{
        fontFamily: 'Roboto',
        color: 'white',
        margin: '33px 0 0 24px'
    },

    social:{
        margin :'33px 51px 0 21px',
        color :'white'
    },

    sectionTwo :{
        margin:'131px 0 0 181px'
    },

    sectionLink:{
        fontFamily: 'Roboto',
        color : 'white',
        textTransform :'none',
        letterSpacing :'none',
        "&:hover": {
            background: "transparent"
        },
    },

    sectionThere :{
        margin:'131px 0 0 200px'
    },

    subFooter:{
        width : '100%',
        height : '46px',
        backgroundColor : '#0B7AD4',
        boxShadow: '0 -1px 3px 4px #0B7AD4'
    },

    subTitle:{
        textAlign : 'center',
        color : 'white',
        fontFamily: 'Roboto',
        paddingTop : '10px'
    }
});


export default connect(mapStateToProps , mapActionsToProps)(withStyles(styles)(footer))
