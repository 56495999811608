import firebase from 'firebase/app'
import 'firebase/storage'


const config = {
    apiKey: "AIzaSyAVaufVTJehAGHCyQUHPdzlo3LSn1RGGoY",
    authDomain: "span-1c8da.firebaseapp.com",
    projectId: "span-1c8da",
    storageBucket: "span-1c8da.appspot.com",
    messagingSenderId: "319553119557",
    appId: "1:319553119557:web:9bc3d0e5e405cfb1b3ca2c",
    measurementId: "G-2PSBHGW5K2"
  };


  firebase.initializeApp(config);

  const storage = firebase.storage()
  

  export {storage , firebase as default}